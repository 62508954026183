<template>
  <el-container
    class="form-design"
    v-loading.fullscreen.lock="loading"
  >
    <el-aside width="320px">
      <slot name="drag"></slot>
      <DragPanel> </DragPanel>
    </el-aside>
    <el-main>
      <el-row class="form-design">
        <el-col
          :span="18"
          class="height-all"
        >
          <el-card class="box-card form-contains center-card">
            <!-- 预览头部默认隐藏 -->
            <div
              slot="header"
              v-if="development"
              class="clearfix"
            >
              <span style="float: right;">
                <slot name="controlButton">

                </slot>
                <el-button
                  v-if="clear"
                  type="text"
                  size="medium"
                  icon="el-icon-delete"
                  @click="handleClear"
                >清空</el-button>
                <el-button
                  v-if="preview"
                  type="text"
                  size="medium"
                  icon="el-icon-view"
                  @click="handlePreview"
                >预览</el-button>
                <el-button
                  v-if="reder"
                  type="text"
                  size="medium"
                  icon="el-icon-view"
                  @click="handleRender"
                >渲染</el-button>
                <el-button
                  v-if="imp"
                  type="text"
                  size="medium"
                  icon="el-icon-download"
                  @click="handleImport"
                >导入{{development}}</el-button>
                <el-button
                  v-if="exp"
                  type="text"
                  size="medium"
                  icon="el-icon-upload2"
                  @click="handleGenerateJson"
                >导出</el-button>
              </span>
            </div>
            <DesignPanel
              :data="data"
              ref="dragPanel"
              :selectForm="selectForm"
              @changeSelectItem="changeSelectItem"
            />

          </el-card>
        </el-col>
        <el-col
          :span="6"
          class="height-all"
        >
          <el-card class="box-card form-properties right-card">
            <Properties
              :data="data"
              :selectItem="selectItem"
            >
              <template
                slot="custom-properties"
                :selectItem="selectItem"
              >
                <slot
                  name="custom-properties"
                  :selectItem="selectItem"
                ></slot>
              </template>
              <template
                slot="form-extend-properties"
                :data="data"
              >
                <slot
                  name="form-extend-properties"
                  :data="data"
                ></slot>
              </template>
              <template
                slot="extend-tab"
                :data="data"
              >
                <slot
                  name="extend-tab"
                  :data="data"
                ></slot>
              </template>
            </Properties>
          </el-card>
        </el-col>
      </el-row>

    </el-main>

    <Preview
      v-if="previewVisible"
      ref="preview"
    />

    <renderPreview
      ref="renderPreview"
      v-if="renderVisisble"
    />

    <previewCode
      ref="model"
      v-if="modelVisible"
    />

    <el-dialog
      title="模板数据"
      :visible.sync="importVisible"
      :append-to-body="true"
      style="top:20px;"
      width="850px"
    >

      <el-input
        type="textarea"
        :rows="3"
        v-model="importText"
      >

      </el-input>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="mini"
          @click="importVisible = false"
        >取 消</el-button>
        <el-button
          size="mini"
          type="primary"
          @click="importModel"
        >确 定</el-button>
      </span>
    </el-dialog>

  </el-container>
</template>

<script>

import cloneDeep from 'lodash/cloneDeep'

import DragPanel from './drag-panel/index'
import DesignPanel from './design-panel/index'
import Properties from './properties/index'

import Preview from '../preview/index'
import previewCode from '../preview/preview-code'

import renderPreview from '../preview/render'

// 用bus事件委托预览表单
import Bus from '@/utils/bus.js'
// import { throttle } from '@/utils'
import api from '@/api'
export default {
  name: 'ng-form-design',
  data () {
    return {
      loading: false,
      id: '',
      previewVisible: false,
      modelJson: {},

      modelVisible: false,
      importVisible: false,
      importText: '',

      renderVisisble: false,
      // 基础配置
      data: {
        list: [],
        config: {
          labelPosition: 'left',
          labelWidth: 120,
          size: 'small',
          outputHidden: true, //  是否输出隐藏字段的值 默认打开,所有字段都输出
          hideRequiredMark: true,
          customStyle: ''
        }
      },

      previewOptions: {
        width: 850
      },
      selectItem: {} // 选中的元素
    }
  },
  watch: {
    data: {
      handler (newValue, oldValue) {
        if (this.selectForm && this.selectForm.id) {
          // 修改数据发生变化
          // this.selectForm.change = true
          const jsonForm = JSON.stringify(this.selectForm.htmlModel)
          const jsonData = JSON.stringify(this.data)
          if (jsonForm !== jsonData) {
            this.$set(this.selectForm, 'change', true)
            this.$set(this.selectForm, 'htmlModel', cloneDeep(this.data))
          }
        }
      },
      // 对象的深度监听deep，默认为false不进行深度监听
      deep: true
    },
    selectForm: {
      handler (newValue, oldValue) {
        if (newValue && newValue.id !== (oldValue ? oldValue.id : '')) {
          // 修改数据发生变化

          const htmlModel = newValue.htmlModel

          const jsonModel = htmlModel ? (typeof htmlModel === 'object' ? htmlModel : JSON.parse(htmlModel)) : null
          console.log(jsonModel)
          this.initModel(cloneDeep(htmlModel))
        }
      },
      // 对象的深度监听deep，默认为false不进行深度监听
      deep: true
    }

  },
  props: {
    selectForm: {
      type: Object
    },
    customComponents: {
      type: Array,
      default: () => []
    },
    config: {
      type: Object,
      default: () => { return {} }
    },
    // 按钮显示隐藏
    clear: {
      type: Boolean,
      default: true
    },
    preview: {
      type: Boolean,
      default: true
    },
    reder: {
      type: Boolean,
      default: true
    },
    imp: {
      type: Boolean,
      default: true
    },
    exp: {
      type: Boolean,
      default: true
    }
  },
  provide: function () {
    return {
      customC: this.customComponents
    }
  },
  components: {
    DesignPanel, DragPanel, Properties, Preview, previewCode, renderPreview
  },
  created () {
    // if( this.customComponents && this.customComponents.length > 0) {
    //   window.customComponents = this.customComponents
    // }
    if (this.config.httpConfig) {
      window.httpConfig = this.config.httpConfig
    }
  },
  computed: {
    development () {
      return process.env.NODE_ENV === 'development'
    }
  },
  mounted () {
    this.id = this.$route.query.id
    Bus.$on('previewFormEmit', () => {
      this.handlePreview()
    })
    Bus.$off('keepDynamicFormEmit')
    Bus.$on('keepDynamicFormEmit', () => {
      // console.log(this.data)
      this.commitFormConfig()
    })
  },
  methods: {
    trim (str) {
      return str.replace(/(^\s*)|(\s*$)/g, '')
    },
    changeSelectItem (item) {
      this.selectItem = item
    },
    handlePreview () {
      this.previewVisible = true
      const this_ = this
      this.$nextTick(() => {
        this_.$refs.preview.jsonData = this_.data
        this_.$refs.preview.previewWidth = this_.previewOptions.width
        this_.$refs.preview.visible = true
      })
    },
    commitFormConfig () {
      // console.log(this.data)
      const { list } = this.data
      const batch = list.find(item => {
        return item.type === 'batch'
      })
      if (batch && batch.list.length === 0) {
        this.$message({
          type: 'warning',
          message: '动态表格不能为空！'
        })
        return false
      }
      if (this.data.list.length === 0) {
        this.$message({
          type: 'warning',
          message: '请至少选择一个控件！'
        })
        return false
      }
      const newArr = this.data.list.map(item => this.trim(item.label))
      const arrSet = new Set(newArr)
      if (arrSet.size !== this.data.list.length) {
        this.$message({
          type: 'warning',
          message: '同一表单内的控件名称不能重复，请修改后再保存。'
        })
        return false
      }
      const data = {
        id: this.$route.query.id,
        value: JSON.stringify(this.data),
        version: 0
      }
      // console.log(data)
      this.loading = true
      this.axios.post(api.editWorkConfigHtml, data).then(res => {
        console.log(res)
        if (res.data.code === 0) {
          Bus.$emit('saveFormSuccessEmit')
        }
        this.loading = false
      })
    },
    handleClear () {
      this.$confirm('清空后无法恢复,请确认操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.data.list = []
      })
    },
    // 导入
    handleImport () {
      this.importVisible = true
      this.importText = ''
    },
    importModel () {
      if (!this.importText) {
        this.$message({
          type: 'warning',
          message: '文本不能为空！'
        })
        return
      }

      const importData = JSON.parse(this.importText)
      if (importData) {
        this.data = importData
      }

      this.importVisible = false
    },
    initModel (model) {
      if (model) { this.data = model } else {
        this.data.list = []
      }

      this.selectItem = {}
      this.$refs.dragPanel.selectItem = {}
    },
    getModel () {
      return this.data
    },
    handleRender () {
      // console.log(this.data)
      this.renderVisisble = true
      this.$nextTick(() => {
        this.$refs.renderPreview.init(this.data)
      })
    },
    handleGenerateJson () {
      this.modelVisible = true
      this.$nextTick(() => {
        this.$refs.model.init(this.data)
      })
    }
  }
}
</script>

<style >
</style>
