import * as types from '../types'

// initial state
const state = {
  investmentSelectList: [],
  gbidsList: []
}

// mutations
const mutations = {
  // 设置自定义招商列表
  [types.SET_INVESTMENT_LIST] (state, investmentSelectList) {
    state.investmentSelectList = investmentSelectList
  },
  // 设置选中品牌gbids
  [types.SET_GBIDS_LIST] (state, gbidsList) {
    state.gbidsList = gbidsList
  }
}

export default {
  state,
  mutations
}
