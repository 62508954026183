import axios from 'axios'
import { Message } from 'element-ui'
import {
  getCookie,
  removeCookie
} from './index'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})

service.interceptors.request.use(
  config => {
    // do something before request is sent
    // if (getCookie('wisdom_token')) {
    // let each request carry token
    // please modify it according to the actual situation
    if (config.url.indexOf('.json') === -1) {
      config.headers.token = getCookie('wisdom_token') || ''
      // if (config.url.indexOf('userLogin') === -1) {
      if (getCookie('wisdom_token')) {
        // console.log(timeStamp)
        let timeStamp = 0
        const diffTime = localStorage.getItem('diffTimeVal')
        if (parseInt(diffTime) > 0) {
          timeStamp = new Date().valueOf() + parseInt(diffTime)
        } else {
          timeStamp = new Date().valueOf() - parseInt(diffTime)
        }
        config.headers.timeStamp = timeStamp
      }
      // }
      config.headers.nonce = Math.random()
      config.headers.sndata = 'gyzs_u5'
    }
    // }
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    localStorage.setItem('sysTime', new Date(response.headers.date).getTime())
    if (response.data.code !== 0 &&
        response.data.code !== 1008 &&
        response.data.code !== 1032 &&
        response.data.code !== 1102 &&
        response.data.code !== 1101 &&
        response.data.code !== 1096 &&
        response.data.code !== 1002) {
      if (window.location.href.indexOf('login') !== -1) {
        return false
      }
      if (response.data.code === -1) {
        Message.error(response.data.msg)
        // 登录失效
      } else if (response.data.code === 1010) {
        Message.error('用户登录信息已失效，请重新登录！')
        removeCookie('wisdom_token')
        removeCookie('wisdom_user')
        localStorage.removeItem('wisdom_interests')
        window.location.replace('/#/login?backurl=' + window.location.href)
      } else {
        if (
          response.config.url.indexOf('/export') === -1 &&
          response.config.url.indexOf('/get_excel') === -1 &&
          response.config.url.indexOf('.json') === -1 &&
          response.config.url.indexOf('/apiexport/dp') === -1 &&
          response.config.url.indexOf('/rentplan/downloadBerth') === -1
        ) {
          Message.error(response.data.msg)
        } else {
          // window.location.replace('/#/login?backurl=' + window.location.href)
        }
      }
    }
    return response
  },
  error => {
    if (error.response) {
      Message.error('网络不稳定，请稍后再试！')
      return Promise.reject(error.response.data)
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
