<!--
  * author lyf
  * date 2020-07-06
  * description 可拖拽元素的列表 包含基础组件和布局组件
-->
<template>
  <el-card class="box-card form-item left-card">
    <el-collapse
      v-model="actives"
      accordion
    >
      <div class="card-tips-text">基础组件
        <el-tooltip
          class="item"
          effect="dark"
          content="基础类型控件"
          placement="right"
        >
          <span>
            <i class="icon-help-tips"></i>
          </span>
        </el-tooltip>
      </div>
      <DragItem
        :list="basicsArray"
        @generateKey="generateKey"
        @handleListPush="handleListPush"
        @start="handleStart"
      />
      <!-- 个性化控件 -->
      <div class="card-tips-text">高级组件
        <el-tooltip
          class="item"
          effect="dark"
          content="系统预设数据源"
          placement="right"
        >
          <span>
            <i class="icon-help-tips"></i>
          </span>
        </el-tooltip>
      </div>
      <DragItem
        :list="personalArray"
        @generateKey="generateKey"
        @handleListPush="handleListPush"
        @start="handleStart"
      />
      <!-- 自定义控件 -->
      <!-- <div class="card-tips-text">基础组件
        <el-tooltip
          class="item"
          effect="dark"
          content="基础类型控件。"
          placement="right"
        >
          <span>
            <i class="icon-help-tips"></i>
          </span>
        </el-tooltip>
      </div>
      <DragItem
        :list="customComponents"
        @generateKey="generateKey"
        @handleListPush="handleListPush"
        @start="handleStart"
      /> -->
      <div class="card-tips-text">样式组件
        <el-tooltip
          class="item"
          effect="dark"
          content="样式类型控件"
          placement="right"
        >
          <span>
            <i class="icon-help-tips"></i>
          </span>
        </el-tooltip>
      </div>
      <DragItem
        :list="layoutArray"
        @generateKey="generateKey"
        @handleListPush="handleListPush"
        @start="handleStart"
      />
    </el-collapse>
  </el-card>
</template>
<script>
import { basicsList, layoutList, personalList, noModelList } from '../config.js'
import DragItem from './item'
export default {
  components: {
    DragItem
  },
  data () {
    return {
      actives: ['1'],
      noModel: noModelList,
      startType: '',
      data: {
        list: [],
        config: {
          layout: 'horizontal',
          labelCol: { span: 4 },
          wrapperCol: { span: 18 },
          hideRequiredMark: false,
          customStyle: ''
        }
      },
      previewOptions: {
        width: 850
      },
      selectItem: {
        key: ''
      }
    }
  },
  computed: {
    basicsArray () {
      // 计算需要显示的基础字段
      const blist = basicsList
      blist.forEach(t => {
        t.options.dynamicHide = false
        t.options.dynamicHideValue = ''
        if (!t.key) {
          const key = t.type + '_' + new Date().getTime()
          t.key = key
          t.model = key
        }
      })
      return blist
    },
    personalArray () {
      // 计算需要显示的基础字段
      const blist = personalList
      blist.forEach(t => {
        t.options.dynamicHide = false
        t.options.dynamicHideValue = ''
        if (!t.key) {
          const key = t.type + '_' + new Date().getTime()
          t.key = key
          t.model = key
        }
      })
      return blist
    },
    layoutArray () {
      const llist = layoutList
      llist.forEach(t => {
        t.options.dynamicVisible = false
        t.options.dynamicVisibleValue = ''
        if (!t.key) {
          const key = t.type + '_' + new Date().getTime()
          t.key = key
          t.model = key
        }
      })
      return llist
    }
  },
  created () {
    // 2021-05-17 lyf 初始化回填默认key和model
    if (this.customComponents && this.customComponents.length > 0) {
      this.customComponents.forEach(t => {
        if (!t.key) {
          const key = t.type + '_' + new Date().getTime()
          t.key = key
          t.model = key
        }
      })
      // console.log(this.customComponents)
    }
  },
  inject: {
    customComponents: {
      from: 'customC',
      default: () => []
    }
  },
  methods: {
    generateKey (list, index) {
      // 生成key值
      const key = list[index].type + '_' + new Date().getTime()
      console.log(key) // 生成label数据库字段值
      this.$set(list, index, {
        ...list[index],
        key,
        model: key
      })
      if (this.noModel.includes(list[index].type)) {
        // 删除不需要的model属性
        delete list[index].model
      }
    },
    handleListPush (item) {
      // 双击控件按钮push到list
      // 生成key值
      if (!this.selectItem.key) {
        // 在没有选择表单时，将数据push到this.data.list
        const key = item.type + '_' + new Date().getTime()
        item = {
          ...item,
          key,
          model: key
        }
        if (this.noModel.includes(item.type)) {
          // 删除不需要的model属性
          delete item.model
        }
        const itemString = JSON.stringify(item)
        const record = JSON.parse(itemString)
        // 删除icon及compoent属性
        delete record.icon
        delete record.component
        this.data.list.push(record)

        this.$emit('handleSetSelectItem', record)
        return false
      }
    },
    handleStart (list, index) {
      this.generateKey(list, index)
      const type = list[index].type
      this.startType = type
    }
  }
}

</script>
