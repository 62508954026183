var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":!_vm.dataForm._id ? '新增' : '修改',"close-on-click-modal":false,"append-to-body":true,"lock-scroll":false,"visible":_vm.visible,"id":_vm.randomId},on:{"update:visible":function($event){_vm.visible=$event}}},[(
      typeof _vm.formTemplate.list !== 'undefined' && typeof _vm.formTemplate.config !== 'undefined'
    )?_c('el-form',{ref:"dataForm",staticClass:"form-build form-design form-design-batch",attrs:{"label-position":_vm.formTemplate.config.labelPosition,"hide-required-asterisk":_vm.formTemplate.config.hideRequiredMark,"label-width":_vm.formTemplate.config.labelWidth + 'px',"model":_vm.dataForm,"size":"mini"}},[_vm._l((_vm.formTemplate.list),function(item,index){return [(
    !(item.options.hidden === true) &&
      ([
        'input',
        'textarea',
        'date',
        'time',
        'datePicker',
        'number',
        'radio',
        'checkbox',
        'select',
        'rate',
        'switch',
        'slider',
        'uploadImg',
        'uploadFile',
        'cascader'
      ].includes(item.type) || _vm.customList.includes(item.type) ) && _vm.dynamicVisibleItem(item)  )?_c('el-form-item',{key:index,attrs:{"label":_vm.formTemplate.config.labelWidth > 0 ? item.label : null,"rules":_vm.recordRules(item),"prop":item.rules && item.rules.length > 0 ? item.model : null,"id":item.model,"name":item.model}},[(item.options.tooptip && item.options.tooptip.trim())?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","enterable":false,"open-delay":500,"placement":"top-start"}},[_c('div',{staticClass:"tooltip-content",attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(item.options.tooptip))]),_c('BaseItem',{attrs:{"models":_vm.dataForm,"formConfig":_vm.formTemplate.config,"renderPreview":_vm.renderPreview,"record":item,"disabled":_vm.disabled || (item.options.disabled && !item.options.dynamicDisabled )}})],1):_c('BaseItem',{attrs:{"models":_vm.dataForm,"formConfig":_vm.formTemplate.config,"renderPreview":_vm.renderPreview,"record":item,"disabled":_vm.disabled || (item.options.disabled && !item.options.dynamicDisabled )}})],1):_vm._e()]}),_c('el-form-item',{attrs:{"label":"排序","prop":"seq"}},[(_vm.renderPreview)?[_vm._v(" "+_vm._s(_vm.dataForm.seq)+" ")]:[_c('el-input-number',{attrs:{"controls-position":"right","min":0,"label":"排序号","disabled":_vm.renderPreview},model:{value:(_vm.dataForm.seq),callback:function ($$v) {_vm.$set(_vm.dataForm, "seq", $$v)},expression:"dataForm.seq"}})]],2)],2):_vm._e(),_c('div',{staticClass:"mod-footer"},[_c('el-button',{on:{"click":function($event){_vm.visible = false}}},[_vm._v("取消")]),(!_vm.renderPreview)?_c('el-button',{attrs:{"disabled":_vm.loading,"type":"primary"},on:{"click":function($event){return _vm.dataFormSubmit()}}},[_vm._v("确定")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }