// 设置token
export const SET_TOKEN = 'SET_TOKEN'

// 设置用户信息
export const SET_USER = 'SET_USER'

// 设置自定义招商搜索条件
export const SET_SELF_SEARCH = 'SET_SELF_SEARCH'

// 设置自定义招商列表
export const SET_SELF_LIST = 'SET_SELF_LIST'

// 设置请求失败
export const SET_LOADING_ERROR = 'SET_LOADING_ERROR'

// 设置招商选择品牌列表
export const SET_INVESTMENT_LIST = 'SET_INVESTMENT_LIST'

// 设置选中品牌gbids
export const SET_GBIDS_LIST = 'SET_GBIDS_LIST'
