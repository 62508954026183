import * as types from '../types'

const state = {
  token: '',
  user: {
    confIds: []
  },
  loadingError: false
}

const mutations = {
  // 设置token
  [types.SET_TOKEN] (state, token) {
    state.token = token
  },
  // 设置管理员信息
  [types.SET_USER] (state, user) {
    state.user = user
  },
  // 设置请求失败
  [types.SET_LOADING_ERROR] (state, loadingError) {
    state.loadingError = loadingError
  }
}

export default {
  state,
  mutations
}
