import api from '../api'
import axios from './http'
import store from '../store'
import { removeCookie } from './index'

/**
 * loginAuth 获取用户信息
 * @param {String} uid     用户id
 * @result {String} token  用户token
 */
export const getUserInfo = (token, uid) => {
  return new Promise((resolve, reject) => {
    axios.post(api.userInfo, { userid: uid })
      .then((res) => {
        // console.log(res)
        if (!res) {
          // window.location.replace(window.location.href + 'login')
          removeCookie('wisdom_token')
          removeCookie('wisdom_user')
          localStorage.removeItem('wisdom_interests')
          window.location.replace('/#/login?backurl=' + window.location.href)
        } else {
          store.dispatch('setToken', {
            token: token,
            user: res.data.data
          })
        }
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getUserInterests = (uid) => {
  return new Promise((resolve) => {
    axios.post(api.getEditionInterests, { id: uid })
      .then((res) => {
        // console.log(res)
        if (!res) {
          removeCookie('wisdom_token')
          removeCookie('wisdom_user')
          localStorage.removeItem('wisdom_interests')
        } else {
          localStorage.setItem('wisdom_interests', JSON.stringify(res.data.data))
        }
        resolve(res)
      })
  })
}
