// 登录token
export const token = state => state.common.token

// 设置用户信息
export const user = state => state.common.user

// 设置自定义搜索
export const selfSearch = state => state.selfBrand.selfSearch

// 设置自定义招商列表
export const selfList = state => state.selfBrand.selfList

// 设置请求失败
export const loadingError = state => state.common.loadingError

// 设置招商选择品牌列表
export const investmentSelectList = state => state.work.investmentSelectList

// 设置选中品牌gbids
export const gbidsList = state => state.work.gbidsList
