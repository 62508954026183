<template>
  <!-- :action="{
          target: '/imgapi/api/server-file/file/upload/oss',
          fileName: 'files'
        }" -->
        <!-- action="/imgapi/api/server-file/file/upload/oss"
        name="files" -->
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
  >
    <div v-if="!renderPreview">
      <el-upload
        v-if="record.type === 'uploadImg'"
        action="https://img3.winshangdata.com/api/server-file/file/noAuth/upload/oss"
        name="files"
        list-type="picture-card"
        :file-list="currentValue"
        :on-success="handleChange"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :before-upload="beforeUploadImg"
        :limit="record.options.limit"
        :class="{hidden: hideUpload}"
        :disabled="record.options.disabled"
        :accept="pathName === 'formSubmitH5' ? '' : '.jpg,.png,.jpeg'"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-upload
        v-if="record.type === 'uploadFile'"
        ref="uploadFile"
        :action="pathName === 'formSubmitH5' && userType ? uploadUrl : uploadUrlMerchant"
        :data="pathName === 'formSubmitH5' && userType ? {}: params"
        :headers="token"
        :file-list="currentValue"
        :on-success="fileSuccess"
        :before-upload="beforeUpload"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :disabled="record.options.disabled"
        :auto-upload="true"
        :limit="record.options.limit"
        :class="{hiddenFile: hideUploadFile}"
        :accept="pathName === 'formSubmitH5' ? '' : '.doc,.docx,.xls,.xlsx,.csv,.pdf,.rar,.zip,.7z'"
      >
        <el-button
          size="small"
          type="primary"
        >点击上传</el-button>
      </el-upload>
    </div>
    <div v-else>
      <template v-if="record.type === 'uploadImg'">
        <el-image
          class="upload-img"
          v-for="(item, index) in models[record.model]"
          :key="index"
          :src="item.url"
          :preview-src-list="srcList"
        ></el-image>
      </template>
      <template v-if="record.type === 'uploadFile'">
        <div
          v-for="(item, index) in models[record.model]"
          :key="index"
          class="file-list"
        >
          <span>{{item.name}}</span><i @click="downFile(item.url, item.name)">点击下载</i>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import api from '@/api'
import { getCookie } from '@/utils'
import { download } from '@/utils/downFile.js'
import Bus from '@/utils/bus.js'
export default {
  data () {
    return {
      uploadUrl: api.uploadFile,
      uploadUrlMerchant: api.uploadFileMerchant, // 商户小程序上传接口
      token: {
        token: getCookie('wisdom_token')
      },
      hideUpload: false,
      hideUploadFile: false,
      srcList: [],
      pathName: '',
      userType: 1, // 1为商管小程序，0为商户小程序
      params: {
        topUid: getCookie('wisdom_user')
      },
      loading: false
    }
  },
  props: {
    // 是否预览结果表单
    renderPreview: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default () {
        return []
      }
    },
    record: { // 组件数据
      type: Object,
      required: true
    },
    models: { // 表单数组
      type: Object,
      required: true
    },
    limit: {
      type: Number,
      default: 3
    },
    dynamicDisabled: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.pathName = this.$route.name
    this.userType = Number(this.$route.query.userType)
    Bus.$on('previewPhoneBusEmit', (res) => {
      if (res) {
        this.pathName = 'formSubmitH5'
      } else {
        this.pathName = ''
      }
    })
    // console.log('this.value', this.value)
    // console.log('this.record', this.record)
    // console.log('this.models', this.models)
    if (this.record.type === 'uploadImg') {
      if (this.models[this.record.model] && this.models[this.record.model].length > 0) {
        this.srcList = this.models[this.record.model].map(item => {
          return item.url
        })
      }
    }
  },
  computed: {
    currentValue () {
      return Array.isArray(this.value) ? this.value : []
    }
  },
  methods: {
    downFile (url, name) {
      download(url, name)
      // const blob = new Blob([url], { // this.detailinfo是后台返回给前台的文档下载链接
      //   type: 'application/json'
      // })
      // const objectUrl = URL.createObjectURL(blob)
      // const link = document.createElement('a') // 我们用模拟q标签点击事件
      // const fname = name
      // link.href = objectUrl
      // link.setAttribute('download', fname)
      // document.body.appendChild(link)
      // link.click() // 点击
      // document.body.removeChild(link) // 下载完成移除元素
      // window.URL.revokeObjectURL(URL) // 释放掉blob对象
    },
    beforeUploadImg (file) {
      // console.log(file.name.split('.')[1])
      if (file.name.split('.')[1] !== 'png' && file.name.split('.')[1] !== 'jpg' && file.name.split('.')[1] !== 'jpeg') {
        this.$message.error('上传图片格式有误!')
        return false
      }
      this.hideUpload = this.models[this.record.model].length >= this.record.options.limit
    },
    handleChange (res, file, fileList) {
      console.log(fileList)
      if (file.status === 'ready') {
        return
      }
      const currentSuccessFiles = fileList
        .filter(x => x.status === 'success' || x.response.success)
        .map(x => {
          if (x.response) {
            return {
              url: x.response.data[0].url,
              name: x.response.data[0].name
            }
          } else {
            return x
          }
        })
      this.$emit('input', currentSuccessFiles)
      this.hideUpload = this.models[this.record.model].length >= this.record.options.limit
    },
    beforeRemove (file) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleRemove (file, fileList) {
      this.hideUpload = this.models[this.record.model].length >= this.record.options.limit
      this.handleChange(null, file, fileList)
    },
    beforeUpload (file, fileList) {
      // console.log(file, fileList)
      const isLt10M = file.size / 1024 / 1024 < 50
      const fileName = file.name.split('.')
      if (fileName[fileName.length - 1] !== 'doc' &&
        fileName[fileName.length - 1] !== 'docx' &&
        fileName[fileName.length - 1] !== 'xls' &&
        fileName[fileName.length - 1] !== 'xlsx' &&
        fileName[fileName.length - 1] !== 'csv' &&
        fileName[fileName.length - 1] !== 'pdf' &&
        fileName[fileName.length - 1] !== 'rar' &&
        fileName[fileName.length - 1] !== 'zip' &&
        fileName[fileName.length - 1] !== '7z'
      ) {
        this.$message.error('上传文件格式有误!')
        const uid = file.uid // 关键作用代码，去除文件列表失败文件
        const idx = this.$refs.uploadFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
        this.$refs.uploadFile.uploadFiles.splice(idx, 1)
        return false
      }
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过 50MB!')
        const uid = file.uid // 关键作用代码，去除文件列表失败文件
        const idx = this.$refs.uploadFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
        this.$refs.uploadFile.uploadFiles.splice(idx, 1)
        return false
      }
      this.loading = true
    },
    fileSuccess (res, file, fileList) {
      console.log(res, file, fileList)
      if (res.code === 0) {
        if (file.status === 'ready') {
          return
        }
        const currentSuccessFiles = fileList
          .filter(x => x.status === 'success' || x.response.success)
          .map(x => {
            if (x.response) {
              return {
                url: x.response.data,
                name: x.name
              }
            } else {
              return x
            }
          })
        this.$emit('input', currentSuccessFiles)
        this.loading = false
        this.hideUploadFile = this.models[this.record.model].length >= this.record.options.limit
      }
    }
  }
}
</script>
