<template>
  <el-dialog
    title="预览"
    :visible.sync="visible"
    style="top:20px;"
    :append-to-body="true"
    class="design-preview"
    :destroy-on-close="true"
    :width="`${previewWidth}px`"
  >
    <div class="preview-main">
      <i
        class="el-icon-mobile-phone"
        @click="previewPhone"
      ></i>
    </div>
    <div class="item-main">
      <FormBuild
        :custom-components="customComponents"
        :formTemplate="jsonData"
        :models="models"
        ref="formBuild"
      />
      <PreviewCode ref="previewCode" />
      <renderPreview
        ref="renderPreview"
        v-if="renderVisisble"
      />
    </div>
    <span
      slot="footer"
      class="dialog-footer"
      v-if="development"
    >
      <el-button
        size="mini"
        @click="visible = false"
      >取 消</el-button>
      <el-button
        size="mini"
        @click="handleGetData"
      >获取数据</el-button>
      <el-button
        size="mini"
        @click="handleRender"
      >渲染</el-button>
      <el-button
        size="mini"
        @click="handleValidator"
      >验证</el-button>
      <el-button
        size="mini"
        type="primary"
        @click="visible = false"
      >确 定</el-button>
    </span>
    <div
      class="iphone-fixed"
      v-if="isPhonePre"
    >
      <div class="phone-preview-wrap">
        <span
          class="el-icon-circle-close"
          @click="closePreviewPhone"
        ></span>
        <div class="phone-preview-main">
          <FormBuild
            :custom-components="customComponents"
            :formTemplate="jsonData"
            :models="models"
            :phone="true"
            ref="formBuild"
          />
        </div>
      </div>
    </div>

  </el-dialog>
</template>
<script>
import FormBuild from '../form-build/index'
import PreviewCode from './preview-code'

import renderPreview from './render'

import Bus from '@/utils/bus.js'
export default {
  name: 'ng-form-preview',
  data () {
    return {
      visible: false,
      renderVisisble: false,
      previewWidth: 850,
      models: {},
      jsonData: {},
      isPhonePre: false
    }
  },
  components: {
    PreviewCode, FormBuild, renderPreview
  },
  inject: {
    customComponents: {
      from: 'customC',
      default: () => []
    }
  },
  computed: {
    development () {
      return process.env.NODE_ENV === 'development'
    }
  },
  methods: {
    previewPhone () {
      Bus.$emit('previewPhoneBusEmit', true)
      this.isPhonePre = true
    },
    closePreviewPhone () {
      Bus.$emit('previewPhoneBusEmit', false)
      this.isPhonePre = false
    },
    handleGetData () {
      this.$refs.formBuild.getData()
        .then(res => {
          // this.$refs.previewCode.jsonData = res
          // this.$refs.previewCode.visible = true
          this.$refs.previewCode.init(res)
        })
        .catch(err => {
          console.log(err, '获取数据失败')
        })
    },
    handleValidator () {
      this.$refs.formBuild.getData()
        .then(res => {

        })
      // .catch(err => {
      //   console.log(err, '获取数据失败')
      // })
    },
    handleRender () {
      this.renderVisisble = true
      this.$refs.formBuild.getData()
        .then(res => {
          console.log(this.jsonData)
          console.log(res)
          this.$nextTick(() => {
            this.$refs.renderPreview.init(this.jsonData, res)
          })
        })
        .catch(err => {
          console.log(err, '获取数据失败')
        })
    },
    handleCancel () {
      this.visible = false
    }
  }
}
</script>
