import store from '../store'
/*
  * 防抖动函数
*/
export const debounce = (fn, wait) => {
  let timer
  let timeStamp = 0
  let context
  let args
  const run = () => {
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, wait)
  }
  const clean = () => {
    clearTimeout(timer)
  }
  return function () {
    context = this
    args = arguments
    const now = (new Date()).getTime()
    if (now - timeStamp < wait) {
      clean()
      run()
    } else {
      run()
    }
    timeStamp = now
  }
}

/*
  * 节流函数
*/
export const throttle = (fn, wait) => {
  var pre = Date.now()
  return function () {
    var context = this
    var args = arguments
    var now = Date.now()
    if (now - pre >= wait) {
      fn.apply(context, args)
      pre = Date.now()
    }
  }
}

/**
 * 设置cookie - setCookie(option)
 * @param {Object}   option                   参数
 * @param {String}   option -- key            键
 * @param {String}   option -- value          值
 * @param {Number}   option -- expireDays     过期时间
 * @param {Number}   option -- domain         域
 * @param {Number}   option -- routing        路径
 */
export const setCookie = function (option) {
  const optionDefault = {
    key: '',
    value: '',
    expireDays: 30,
    domain: '',
    routing: '/'
  }
  for (const item in option) {
    optionDefault[item] = option[item]
  }
  const exdate = new Date()
  let cookie
  exdate.setDate(exdate.getDate() + optionDefault.expireDays)
  cookie = `${optionDefault.key}=${encodeURIComponent(optionDefault.value)}`
  optionDefault.domain && (cookie += `; domain=${optionDefault.domain}`)
  optionDefault.routing && (cookie += `; routing=${optionDefault.routing}`)
  optionDefault.expireDays && (cookie += `; expireDays=${exdate}`)
  document.cookie = cookie
}

/**
 * 获取cookie - getCookie(key)
 * @param  {Object}   key                 键
 * @result {*}        result              返回cookie值，没有就返回空
 */
export const getCookie = function (key) {
  const _cookie = document.cookie
  const itemsArr = _cookie.split('; ')
  let item = []
  for (let i = 0; i < itemsArr.length; i++) {
    item = itemsArr[i].split('=')
    if (key === item[0] && item.length === 2) {
      return decodeURIComponent(item[1])
    }
  }
  return ''
}

/**
 * 删除cookie - removeCookie(key)
 * @param  {Object}   key                 键
 */
export const removeCookie = function (key) {
  const date = new Date()
  date.setDate(date.getDate() - 10000 * 3600 * 31)
  document.cookie = `${key}=; path=/; domain=${document.domain.split('.').slice(-2).join('.')}; expires = ${date.toString()}`
}

/*
  * 获取url参数
*/
export const getQueryVariable = function (variable) {
  var query = window.location.hash.substring(1)
  if (query) {
    var hashVars = query.split('?')
    var vars = hashVars[1] && hashVars[1].split('&')
    if (vars) {
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        /* eslint-disable */
        if (pair[0] == variable) {
          return pair[1]
        }
        /* eslint-enable */
      }
    }
  }
  return (false)
}

/**
* 去除对象中参数值为false的参数
* @param  {Object}   obj         列表
*/
export const checkParams = function (obj, order = 1) {
  const objTem = {}
  obj = JSON.parse(JSON.stringify(obj))
  obj.loading = null
  // if (order !== -1) {
  //   obj.radio_type = order
  // }
  for (var variable in obj) {
    /* eslint-disable */
    if (obj.hasOwnProperty(variable)) {
      if (obj[variable] || obj[variable] === '' || obj[variable] === 0) {
        objTem[variable] = obj[variable]
      }
    }
    /* eslint-enable */
  }
  return objTem
}

/**
 * 将后端返回的数据处理成合适的数据结构
 * @param  {Array}   arr         列表
 * @param  {rest}    rest        剩余参数
 */

export const changeTexture = function (arr, ...rest) {
  return arr && arr.map((item) => {
    // 处理默认选中状态为false
    item.active = false
    // 有剩余参数
    if (rest.length > 0) {
      for (const items of rest) {
        for (const key in items) {
          /* eslint-disable */
          if (items.hasOwnProperty(key)) {
            if (key === 'id') {
              item.id = item[items[key]]
            }
            if (key === 'item') {
              item.item = item[items[key]]
            }
          }
          /* eslint-enable */
        }
      }
    }
    return item
  })
}

/**
 * 手机号码有效性 - validateTel(tel)
 * @param  {String}  tel            手机号码
 * @result {boolean} result         验证有效与否
 */
export const validateTel = (tel) => {
  /* eslint-disable */
  let reCn = /^(\+?0?86\-?)?1[3456789]\d{9}$/
  let reTw = /^(\+?886\-?|0)?9\d{8}$/
  /* eslint-enable */
  if (reCn.test(tel) || reTw.test(tel)) {
    return false
  } else {
    return true
  }
}

// 判断浏览器内核
export const browserKernel = () => {
  var u = navigator.userAgent
  return {
    trident: u.indexOf('Trident') > -1, // IE内核
    presto: u.indexOf('Presto') > -1, // opera内核
    webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
    android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, // android终端
    iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
    iPad: u.indexOf('iPad') > -1, // 是否iPad
    webApp: u.indexOf('Safari') === -1, // 是否web应该程序，没有头部与底部
    weixin: u.indexOf('MicroMessenger') > -1, // 是否微信 （2015-01-22新增）
    qq: u.match(/\sQQ/i) === 'qq' // 是否QQ
  }
}

export const getBrowserInfo = () => {
  var userAgent = navigator.userAgent
  var info = ''
  var tempArray = ''
  if (/[Ff]irefox(\/\d+\.\d+)/.test(userAgent)) {
    tempArray = /([Ff]irefox)\/(\d+\.\d+)/.exec(userAgent)
    info += tempArray[1] + tempArray[2]
  } else if (/[Tt]rident(\/\d+\.\d+)/.test(userAgent)) {
    tempArray = /([Tt]rident)\/(\d+\.\d+)/.exec(userAgent)
    if (tempArray[2] === '7.0') {
      tempArray[2] = '11'
    } else if (tempArray[2] === '6.0') {
      tempArray[2] = '10'
    } else if (tempArray[2] === '5.0') {
      tempArray[2] = '9'
    } else if (tempArray[2] === '4.0') {
      tempArray[2] = '8'
    }
    tempArray[1] = 'IE'
    info += tempArray[1] + tempArray[2]
  } else if (/[Cc]hrome\/\d+/.test(userAgent)) {
    tempArray = /([Cc]hrome)\/(\d+)/.exec(userAgent)
    info += tempArray[1] + tempArray[2]
  } else if (/[Vv]ersion\/\d+\.\d+\.\d+(\.\d)* *[Ss]afari/.test(userAgent)) {
    tempArray = /[Vv]ersion\/(\d+\.\d+\.\d+)(\.\d)* *([Ss]afari)/.exec(userAgent)
    info += tempArray[3] + tempArray[1]
  } else if (/[Oo]pera.+[Vv]ersion\/\d+\.\d+/.test(userAgent)) {
    tempArray = /([Oo]pera).+[Vv]ersion\/(\d+)\.\d+/.exec(userAgent)
    info += tempArray[1] + tempArray[2]
  } else {
    info += 'unknown'
  }
  return info
}

/**
 * dateFormat(fmt, date)
 * @param  {String}  fmt            格式要求：yyyy-MM-dd(年月日)、yyyy-MM-dd-hh-mm-ss(年月日时分秒)等
 * @param  {String}  date           日期
 * @result {String} result          按格式要求返回日期
 */
export const dateFormat = (fmt, date) => {
  var o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length)) }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  }
  return fmt
}

/**
 * 判断权限,只传id拿整个模块权限, 传入字符串获取单个按钮权限
 * powerAuth(213, 'addplanbrand') 例: 添加落位品牌权限按钮
 * @param  {Number}  id
 * @param  {String}  typeStr
 */
export const powerAuth = (id, typeStr) => {
  const confIds = store.state.common.user.confIds
  let flag = false
  const isHasModular = confIds.find(item => {
    return item.id === id
  }) || {}
  if (Object.keys(isHasModular).length) {
    flag = true
  }
  if (typeStr && flag) {
    flag = isHasModular.func.split(',').includes(typeStr)
  }
  return flag
}
/**
 * 将数字转换为千分位
 * toThousands(number) 例: 返回千分位
 * @param  {Number}  number
 */
export const toThousands = (number) => {
  var arr = (number + '').split('.')
  var int = arr[0].split('')
  var fraction = arr[1] || ''
  var r = ''
  int.reverse().forEach(function (v, i) {
    if (i !== 0 && i % 3 === 0) {
      r = v + ',' + r
    } else {
      r = v + r
    }
  })
  return r + (fraction ? '.' + fraction : '')
}
