
export const httpGetFileListUrl = 'http://localhost:9000/sxfw/file/list'
export const httpUploadFileUrl = 'http://localhost:9000/sxfw/file/upload'
// export const http_down_file_url = 'http://localhost:9000/sxfw/file/fileDown'

export function dynamicFun (script, model, key = '$') {
  if (!script) return false
  const func = script.indexOf('return') >= 0 ? '{' + script + '}' : 'return (' + script + ')'
  /* eslint-disable */
  const Fn = new Function(key, func)
  /* eslint-enable */
  return Fn(model)
}

export function dateFormater (date, fmt) {
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'H+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length)) }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  }
  return fmt
}
