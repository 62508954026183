export default {
  userLogin: '/apiaiweb/user/userLogin',
  loginOut: '/apiaiweb/user/loginOut',
  userInfo: '/apiaiweb/user/getUserInfo',
  getKPurl: '/apiaiweb/management/getKBurl',
  getDPurl: '/apiaiweb/management/getDPurl',
  getYJurl: '/apiaiweb/management/getYJurl',
  getSysTime: '/apiaiweb/user/getSysTime',
  ipPrompt: '/apiaiweb/user/ipPrompt',
  // 工作台
  getBerthProjectList: '/apiaiweb/berthProject/list',
  getProjectEntiretyData: '/apiaiweb/berthProjectEntirety/getBerthPlanById',
  getPlanList: '/apiaiweb/berthPlan/getBerthPlanByProjectId',
  getPlanEntiretyData: '/apiaiweb/berthProjectEntirety/gePlanEntiretyData',
  savetaskPlanName: '/apiaiweb/berthPlan/save',
  getMapBrandDetails: '/apiaiweb/berthPlanBrand/getByPlanAndBerth',
  recommendBrand: '/apiaiweb/berthPlanBrand/recommendByBrand',
  recommendBerth: '/apiaiweb/berthPlanBrand/recommendByBerth',
  setInvalidBrand: '/apiaiweb/berthBrandSigned/invalid',
  getMatchProjectDetails: '/apiaiweb/berthPlanBrand/getMatchCondition',
  getFilterFloor: '/apiaiweb/berth/listForSelectExcludeSigned',
  searchBrandListMatching: '/apiaiweb/brand/search',
  searchBrandList: '/apiaiweb/branddb/search',
  saveDropBrand: '/apiaiweb/berthPlanBrand/save',
  delBerthPlanBrand: '/apiaiweb/berthPlanBrand/delete',
  myFollowList: '/apiaiweb/berthPlanBrand/listFocus',
  getFloorListSelect: '/apiaiweb/berth/listForSelect',
  getFloorBuilding: '/apiaiweb/berth/listForSelectBuilding',
  mngConfigSetting: '/apiaiweb/mngConf/listByBiz',
  getByBerthId: '/apiaiweb/berth/getByBerthId',
  byGbidSynchronization: '/apiaiweb/berthTask/getByGbid',
  saveSingleBrand: '/apiaiweb/berthBrandSigned/save',
  delSingleBrand: '/apiaiweb/berthBrandSigned/delete',
  exportPlanapi: '/apiaiweb/berthPlan/export',
  uploadPlanBrand: '/apiaiweb/berthPlanBrand/upload',
  uploadSingedBrand: '/apiaiweb/berthBrandSigned/upload',
  batchSave: '/apiaiweb/berthPlanBrand/batchSave',
  batchsaveBerthplan: '/apiaiweb/berthPlanBrand/batchsaveBerthplan',
  batchSingedBrandSave: '/apiaiweb/berthBrandSigned/batchSave',
  exportPlanBrand: '/apiaiweb/berthPlanBrand/exportBerthPlan',
  exportSingedBrand: '/apiaiweb/berthPlanBrand/exportBerthSign',
  isExistBerthTask: '/apiaiweb/berthTask/isExist',
  getBerthTaskPlan: '/apiaiweb/berthTask/getBerthTaskPlan',
  getFollowList: '/apiaiweb/berthTask/listFollower',
  fabuberthTask: '/apiaiweb/berthTask/fabuBerthTask',
  getSearchdeploy: '/apiaiweb/berthTask/deploySearch',
  planContrastFloor: '/apiaiweb/berthProjectEntirety/getFloorsByProjectId',
  getPlanTypeList: '/apiaiweb/berthProjectEntirety/getPlanBrandComparion',
  planContrastBasic: '/apiaiweb/berthProjectEntirety/getSchemeComparisonInfo',
  planContrastType: '/apiaiweb/berthProjectEntirety/getSchemeComparisonAreaPie',
  planContrastShopNumber: '/apiaiweb/berthProjectEntirety/getSchemeComparisonBrandCountPie',
  getPlanBrandInfo: '/apiaiweb/berthProjectEntirety/getPlanBrandComparionBrandInfo',
  getSchemeComparisonBrandCountPie: '/apiaiweb/berthProjectEntirety/getSchemeComparisonInfo',
  defaultProjectInfo: '/apiaiweb/berthProjectEntirety/getDefaultProjectInfo',
  coincidenceBrandNumber: '/apiaiweb/berthProjectEntirety/getProjectComparisonCoincide',
  inputValSearchProject: '/apiaiweb/berthProjectEntirety/getProjectByNameHaveBrand',
  getComparisonProjectArea: '/apiaiweb/berthProjectEntirety/getProjectComparisonAreaPie',
  getComparisonProjectNumber: '/apiaiweb/berthProjectEntirety/getProjectComparisonBrandCount',
  getProjectContrastTypeList: '/apiaiweb/berthProjectEntirety/getProjectBrandComparion',
  getProjectContrastBrandList: '/apiaiweb/berthProjectEntirety/getProjectBrandComparionBrandInfo',
  getFormatAreaPie: '/apiaiweb/berthProjectEntirety/gePlanYetaiAndRentData',
  getFloorDataView: '/apiaiweb/berthProjectEntirety/geFloorDataOverview',
  getDropChart: '/apiaiweb/berthDataOverview/getPlanChart',
  listSignedNum: '/apiaiweb/berthDataOverview/listSignedNum',
  listPlanNum: '/apiaiweb/berthDataOverview/listPlanNum',
  countPpyAddLink: '/apiaiweb/linkman/countPpyAdd',
  saveFollowUpStates: '/apiaiweb/berthFollow/save',
  getUserConfigMap: '/apiaiweb/berthPlan/getBerthPlanLableByUserId',
  berthTasList: '/apiaiweb/berthTaskMng/list',
  getWholeFloorData: '/apiaiweb/berth/listForSelectWithoutIndustry',
  exportBerthList: '/apiaiweb/berthTaskMng/export',
  berthDetailsData: '/apiaiweb/berth/getByBerthId',
  listByBerth: '/apiaiweb/berthTaskMng/listByBerth',
  getBerthTaskList: '/apiaiweb/berthTask/list',
  brandsList: '/apiaiweb/berthTask/brandsList',
  exportInvestement: '/apiaiweb/berthTask/export',
  listPlanBrand: '/apiaiweb/berthTask/listPlanBrand',
  getInvestmentDetails: '/apiaiweb/berthTask/detail',
  deleteInvestment: '/apiaiweb/berthTask/delete',
  followRecordList: '/apiaiweb/berthFollow/listFollow',
  deleteFollowSubMsg: '/apiaiweb/berthFollow/delete',
  saveFollowMsg: '/apiaiweb/berthFollow/saveSubMsg',
  editFollowName: '/apiaiweb/berthTask/updateUser',
  berthSignedMng: '/apiaiweb/OmsContract/getContractPageList',
  getMerchantList: '/apiaiweb/OmsContract/getMerchantList',
  getMallCodeList: '/apiaiweb/OmsContract/getMallCodeList',
  isBerthPlan: '/apiaiweb/berthPlan/isBerthPlan',
  copyPlan: '/apiaiweb/berthPlan/copyBerthPlan',
  getBerthTargetList: '/apiaiweb/berthTarget/list',
  getBerthInfo: '/apiaiweb/berthTarget/getBerthTargetByBerthId',
  saveIndustryMainStore: '/apiaiweb/berth/updateIndustry1AndMainStore',
  addRentMsg: '/apiaiweb/berthTarget/save',
  updateRentMsg: '/apiaiweb/berthTarget/update',
  deleteRentMsg: '/apiaiweb/berthTarget/delete',
  delPlanTask: '/apiaiweb/berthPlan/delete',
  berthChainIsNotRnabled: '/apiaiweb/berthTarget/berthChainIsNotRnabled',
  updateUserConfigMap: '/apiaiweb/berthPlan/updateBerthPlanLable',
  newInformationNum: '/apiaiweb/informationCenter/newInformationNum',
  // 查看详情的时候扣除显示信息
  updateInformationMsgViewed: '/apiaiweb/informationCenter/updateInformationMsgViewed',
  getGdbuilding: '/apiaiweb/berthIndoorMap/getGdbuilding',
  getIndustryIcon: '/apiaiweb/brandsIndustryMap/getIndustryIcon',
  getBrandNews: '/apiaiweb/brand/trend/getBrandNews',
  selectTblTaskContent: '/apiaiweb/berthTask/selectTblTaskContent',
  selectUpdateTblTaskContent: '/apiaiweb/berthTask/selectUpdateTblTaskContent',
  // 自定义业态，一级业态
  getListIndustry1: '/apiaiweb/industry/listIndustry1',
  treeAll: '/apiaiweb/industry/treeAll',
  // 数据洞察
  getSignedChart: '/apiaiweb/berthDataOverview/getSignedChart',
  getYearList: '/apiaiweb/brand/trend/brandTopYear',
  // 项目列表手机端接口
  getAreaList: '/wsapi/accounts/area',
  // getProjectList: '/apiaiweb/project/list3_4', // 仅在测试环境用, 上生产、预生产环境要注释
  getProjectList: '/wsapi/project/list3_4', // 测试期间注释，上生产、预生产环境要打开
  getTypeList: '/wsapi/project/getProjectWuYeLeiXingSelect',
  getProjectFormatList: '/wsapi/userCenter/getYeTaiList',
  getSearchProNameList: '/wsapi/project/getProjectIdAndNameList',
  // 项目详情
  getProjectQyBrands: '/apiaiweb/project/detailQianYueBrand3_6',
  getProjectDetails: '/apiaiweb/project/detailZhaoShangXuQiu',
  getProjectPicList: '/apiaiweb/project/getProjectPicList',
  getProjectDetailsTip: '/apiaiweb/project/getDetailQianYueBrandTip',
  getProjectQyList: '/apiaiweb/project/getYiQianYueList3_5',
  getProjectSurvey: '/apiaiweb/project/detailContent',
  getProjectLookRightDeduction: '/apiaiweb/project/getProjectLookSignedBrandDeduction',
  getProjectDetailIsShow: '/apiaiweb/project/getProjectLookSignedBrandIsShow',
  getProjectDetailLookNum: '/apiaiweb/project/getProjectLookSignedBrandNums',
  getYiQianYueTou: '/apiaiweb/project/getYiQianYueTou',
  // 消息中心
  getEarlyWarningInformation: '/apiaiweb/informationCenter/getEarlyWarningInformation',
  updateInformationMsgIsDelete: '/apiaiweb/informationCenter/updateInformationMsgIsDelete',
  // 品牌详情
  getBrandExpandDemand: '/apiaiweb/branddb/getBrandExpandDemand',
  getBrandPicList: '/apiaiweb/branddb/getBrandPicList',
  brandNewsSearch: '/apiaiweb/brand/trend/brandNewsSearch',
  getLinkmanList4: '/apiaiweb/linkman/getLinkmanList4',
  getProjectByNoTask: '/apiaiweb/berthTask/getProjectByNoTask',
  editBerthTask: '/apiaiweb/berthTask/updateBerthTask',
  getCooperationBrand: '/apiaiweb/berthBrandSigned/listByGbid',
  getCooperationTotal: '/apiaiweb/berthBrandSigned/brandOverview',
  overViewProjectList: '/apiaiweb/berthProject/listUser',
  getBrandDetailIsShow: '/apiaiweb/branddb/getBrandLookRightsIsShow',
  getBrandDetailLookNum: '/apiaiweb/branddb/getBrandLookRightsNums',
  getBrandLookRightDeduction: '/apiaiweb/branddb/getBrandLookRightsDeduction',
  getBrandContactArea: '/apiaiweb/linkman/linkmanIndex',
  getLookLinkManDetail: '/apiaiweb/linkman/lookLinkMan',
  getPileList: '/apiaiweb/branddb/getCaiNiGuanZhu',
  removeContact: '/apiaiweb/linkman/remove_linkman',
  getLinkForkNumV4: '/apiaiweb/brandLookForLink/getLookForLinkNum4',
  addLookForLink: '/apiaiweb/brandLookForLink/add',
  getBrandProvincesShopNum: '/apiaiweb/branddb/getBrandTuoZhanProvinces',
  getBrandInProjectList: '/apiaiweb/branddb/brandAnalysisOfBrandInProject',
  getBrandOpenShopDistribution: '/apiaiweb/branddb/brandAnalysisOfDistributionOfRegion',
  getTuozhanRegion: '/apiaiweb/branddb/getPCBrandTuoZhanRegion',
  getBrandTuozhanProvinceCity: '/apiaiweb/branddb/getBrandTuoZhanProvinceCity',
  getIndexChartNum: '/apiaiweb/branddb/listIndexNumChart',
  getBrandCustomerGroups: '/apiaiweb/branddb/getBrandCustomerGroups',
  followBrand: '/apiaiweb/branddb/shareAdd',
  cancalFollowBrand: '/apiaiweb/branddb/shareDelete',
  getBrandUpdateLog: '/apiaiweb/branddb/getBrandDataUpdateLog',
  getBrandAnalysisOpenClose: '/apiaiweb/branddb/brandAnalysisOfOpenCloseDistribution',
  getBrandAnalysisOpenCloseList: '/apiaiweb/branddb/brandAnalysisOfOpenCloseDistributionList',
  getAnalysisOfCityExpandBusinessDistrict: '/apiaiweb/branddb/getAnalysisOfCityExpandBusinessDistrict',
  getAnalysisOfCityFunctionBusinessDistrict: '/apiaiweb/branddb/getAnalysisOfCityFunctionBusinessDistrict',
  getBrandDetailsNum: '/apiaiweb/branddb/getBrandDetailsNum',
  getBrandOpenShopTrend: '/apiaiweb/branddb/getBrandOpenShopTrend',
  getBrandShoppingCenterLevelPreference: '/apiaiweb/branddb/getBrandShoppingCenterLevelPreference',
  getBrandAnalysisScreeningConditions: '/apiaiweb/branddb/getBrandAnalysisScreeningConditions',
  getBrandAnalysisOpenCloseCitys: '/apiaiweb/branddb/brandAnalysisOfOpenCloseDistribution',
  getBrandAnalysisEnterProjectsNum: '/apiaiweb/branddb/getBrandAnalysisEnterProjectsNum',
  getBrandProjectYear: '/apiaiweb/branddb/getBrandProjectYear',
  listFloorArea: '/apiaiweb/branddb/listFloorArea',
  getBrandIndustryTop10: '/apiaiweb/branddb/getBrandIndustryTop10',
  getBrandAnalysisCompetitionBrandBusinessNum: '/apiaiweb/branddb/getBrandAnalysisCompetitionBrandBusinessNum',
  getBrandChartAnalysis: '/apiaiweb/branddb/getBrandChartAnalysis',
  getBrandAnalysisOfDistributionOfCity: '/apiaiweb/branddb/brandAnalysisOfDistributionOfCity',
  getBrandAnalyzeHaveDate: '/apiaiweb/branddb/getBrandAnalyzeHaveDate',
  getRefer: '/apiaiweb/branddb/getRefer',
  brandAnalysisOfSeasonList: '/apiaiweb/branddb/brandAnalysisOfSeasonList',
  checkLinkman: '/apiaiweb/linkman/checkLinkman',
  exportBrandToEnterpriseBrandTable: '/apiaiweb/branddb/exportBrandToEnterpriseBrandTable',

  // 商业洞察
  getNewsBrandNewsSearch: '/apiaiweb/brand/trend/brandNewsSearch',
  getNewsBrandTopList: '/apiaiweb/brand/trend/brandTopList',
  getNewsBrandCheck: '/apiaiweb/brand/trend/brandCheck',
  getNewsBrandStar: '/apiaiweb/brand/trend/brandStar',
  getNewsBrandNew: '/apiaiweb/brand/trend/brandNew',
  getNewsBrandAttractive: '/apiaiweb/brand/trend/brandAttractive',
  getNewsBrandPotential: '/apiaiweb/brand/trend/brandPotential',
  getNewsDetail: '/apiaiweb/brand/trend/newsDetail',
  getNewsBrandListDetail: '/apiaiweb/brand/trend/brandListDetail',
  getNewsHitFocus: '/apiaiweb/brand/trend/hitFocus',
  getNewsFocus: '/apiaiweb/brand/trend/brandFocus',
  // 常用功能
  getUserConfigList: '/apiaiweb/configdic/getConfigDicListCtr',
  getUserOftenConfigList: '/apiaiweb/configdic/getUserConfigDicList',
  setUserConfig: '/apiaiweb/configdic/addUsedConfigDis',
  deleteUserConfig: '/apiaiweb/configdic/deleteUserConfigdic',
  getModularList: '/apiaiweb/mngUser/getConfigDic',

  // 品牌库
  updateAttention: '/apiaiweb/branddb/attention',
  projectBrandList: '/apiaiweb/branddb/result_brand_list',
  updateMockBrand: '/apiaiweb/branddb/contract_status',
  saveMockCondition: '/apiaiweb/branddb/save_condition',
  getUserRightsNum: '/apiaiweb/invitation/invite_astrict',
  getDownExcelIsShow: '/apiaiweb/branddb/getBrandsOutputisShow',
  selfBrandList: '/apiaiweb/branddb/custom_brand_list',
  getUserFollowList: '/apiaiweb/branddb/listShareUser',
  getExportTips: '/apiaiweb/branddb/getTips',
  getDownExcelListTips: '/apiaiweb/branddb/getBrandsTips',
  getDownExcelList: '/apiaiweb/branddb/exportBrands',
  exportBrandDetail: '/apiaiweb/branddb/export',
  getShopCityTip: '/apiaiweb/address/getShopCityTip',
  getMessageList: '/apiaiweb/management/getMessageList',
  libraryBrandByKeyworld: '/apiaiweb/branddb/select',
  selfSearch: '/apiaiweb/branddb/custom',
  getIntelBrandList: '/apiaiweb/berthPlanBrand/IntelligentMatchByBrand',
  getIntelProjectList: '/apiaiweb/branddb/getProjectList',
  getSignBrandList: '/apiaiweb/matchSigned/list',
  getBrandRights: '/apiaiweb/branddb/getBrandRights',

  // 管理中心
  getById: '/apiaiweb/mngUser/getById',
  getRoleList: '/apiaiweb/management/getRoleList',
  addRole: '/apiaiweb/management/addRole',
  getConfigDicListCtr: '/apiaiweb/configdic/getConfigDicListCtr',
  getUserDepartmentListCtr: '/apiaiweb/management/getUserDepartmentListCtr',
  departmentManagement: '/apiaiweb/management/departmentManagement', // 成员维护部门/项目列表
  getConfigList: '/apiaiweb/management/getConfigList',
  getPermissionsConfigList: '/apiaiweb/management/getPermissionsConfigList',
  getZiUserList: '/apiaiweb/management/getZiUserList',
  getUserProjectList: '/apiaiweb/management/getProjectList',
  getUserProjectList1: '/apiaiweb/management/getMainProject', // 后端又加了个接口
  saveMember: '/apiaiweb/management/save',
  inviteSearchFour: '/apiaiweb/invitation/invitation_condition4',
  inviteBrandList: '/apiaiweb/brandLookForLink/list',
  updateStoreState: '/apiaiweb/brandLookForLink/cancelLook',
  updateMember: '/apiaiweb/mngUser/update',
  updatePersonalInfo: '/apiaiweb/management/update',
  getUserRoleOperateList: '/apiaiweb/management/getUserRoleOperateList',
  updateUsersState: '/apiaiweb/management/updateUsersState',
  updatePwd: '/apiaiweb/management/updatePwd',
  getEditionInterests: '/apiaiweb/mngUser/getPowerById',
  getEditionRecords: '/apiaiweb/mngUser/powerLogList',
  addDeployment: '/apiaiweb/management/addDeployment',
  getUserBykeyword: '/apiaiweb/management/getUserBykeyword',
  deleteUser: '/apiaiweb/management/deleteUser',
  deleteStructure: '/apiaiweb/management/deleteStructure',
  addDeploymentUsers: '/apiaiweb/management/addDeploymentUsers',
  updateDeploymentName: '/apiaiweb/management/updateDeploymentName',
  mobileSector: '/apiaiweb/management/mobileSector',
  updateMobileMember: '/apiaiweb/management/updateMobileMember',
  deleteRole: '/apiaiweb/management/deleteRole',
  updateRole: '/apiaiweb/management/updateRole',
  getUsersByRoleId: '/apiaiweb/management/getUsersByRoleId',
  memberMaintenance: '/apiaiweb/management/memberMaintenance',
  powerlist: '/apiaiweb/mngUser/powerlist', // 权益分配列表
  givePower: '/apiaiweb/mngUser/givePower', // 权益分配
  recoveryPower: '/apiaiweb/mngUser/recoveryPower', // 权益回收
  getSyncBrandList: '/apiaiweb/management/syncBrandList',
  getSyncBrandListNum: '/apiaiweb/management/syncBrandListNum',
  getSyncNewsListNum: '/apiaiweb/management/syncNewsListNum',
  updateUnreadNum: '/apiaiweb/management/updateUnreadNum',
  get_excel: '/apiaiweb/management/get_excel',
  deleteDeployment: '/apiaiweb/management/deleteDeployment',
  systemLogList: '/apiaiweb/operatingLog/list',
  departmentList: '/apiaiweb/management/departmentList',
  memberList: '/apiaiweb/management/memberList',
  resetPwd: '/apiaiweb/management/resetPwd',
  // 客服微信
  getKeFuwetchatCode: '/apiaiweb/management/getKeFuwetchatCode',
  // 商铺签约信息品牌搜索
  getIdByName: '/apiaiweb/brand/getIdByName',

  // 赢商知识库
  getKnowledgeList: '/apiknow/hr/wbms/list',
  getKnowledgeInfo: '/apiknow/hr/wbms/info',
  searchKnowledge: '/apiknow/hr/wbms/search',
  getColumnList: '/apiknow/hr/wbms/columnlist',
  getColumnInfo: '/apiknow/hr/column/info',

  // 招商可视看板
  getAnnualRentHasBeenContracted: '/apiaiweb/aiVisualBoard/getAnnualRentHasBeenContracted', // 1招商整体数据总览
  getAiProgressAnalysis: '/apiaiweb/aiVisualBoard/getAiProgressAnalysis', // 2招商进度对比
  getAiBrandForecast: '/apiaiweb/aiVisualBoard/getAiBrandForecast', // 3当前招商品牌预测
  getSignedAndTargetRentChat: '/apiaiweb/aiVisualBoard/getSignedAndTargetRentChat', // 4签约与租决租金对比
  getSignedBrandAnalysis: '/apiaiweb/aiVisualBoard/getSignedBrandAnalysis', // 5签约品牌分析
  getStateChart: '/apiaiweb/berthTaskAnalyze/getStateChart', // 6对接质量-招商漏斗转化
  getAiStateNumChat: '/apiaiweb/aiVisualBoard/getAiStateNumChat', // 7招商状态数量统计
  getMemberTaskDateChart: '/apiaiweb/berthTeamMembers/getMemberTaskDateChart', // 8成员招商数据分布(饼图)

  // 招商简报
  bulletinTop: '/apiaiweb/bulletin/top',
  teamInvestmentData: '/apiaiweb/bulletin/teamInvestmentData',
  teamInvestmentList: '/apiaiweb/bulletin/teamInvestmentList',
  newSignedDistributed: '/apiaiweb/bulletin/newSignedDistributed',
  noIntentionAndShelvedDistributed: '/apiaiweb/bulletin/noIntentionAndShelvedDistributed',
  projectInvestmentData: '/apiaiweb/bulletin/projectInvestmentData',
  noIntentionAndShelveStatistics: '/apiaiweb/berthWorkPage/noIntentionAndShelveStatistics',
  brandSignedStatistics: '/apiaiweb/berthWorkPage/brandSignedStatistics',
  brandInvestmentStatistics: '/apiaiweb/berthWorkPage/brandInvestmentStatistics',
  brandInvestmentData: '/apiaiweb/bulletin/brandInvestmentData',

  // v5.0.1
  getBBList: '/apiaiweb/management/getBBList',

  // v5.1.0
  // 业态管理
  createFormat: '/apiaiweb/brandsIndustryMap/save',
  checkIndustry: '/apiaiweb/brandsIndustryMap/checkIndustry',
  mergeFormat: '/apiaiweb/brandsIndustryMap/merge',
  updateFormat: '/apiaiweb/brandsIndustryMap/update',
  getIndustryLog: '/apiaiweb/brandsIndustryMap/getIndustryLog',
  moveFormat: '/apiaiweb/brandsIndustryMap/move',
  IndustryTree: '/apiaiweb/brandsIndustryMap/IndustryTree',

  // 报表中心首页需求
  getRecentlyOpened: '/apiaiweb/mngUser/getRecentlyOpened',
  getAddRecentlyOpened: '/apiaiweb/mngUser/getAddRecentlyOpened',

  // 项目大屏需求
  // getSalesRealTimeDataPageList: '/apiaiweb/projectAnalyzeMax/getSalesRealTimeDataPageList',
  getYesterDaySalesDataList: '/apiaiweb/projectAnalyzeMax/getYesterDaySalesDataList', // 昨日销售数据
  getMapManagement: '/apiaiweb/projectAnalyzeMax/getMapManagement',
  aiDataUpdate: '/apiaiweb/projectAnalyzeMax/aiDataUpdate',
  getOverviewValues: '/apiaiweb/projectAnalyzeMax/getOverviewValues',
  getMapValue: '/apiaiweb/projectAnalyzeMax/getMapValue',
  getRentLineChart: '/apiaiweb/projectAnalyzeMax/getRentLineChart',
  getSalesDataOverview: '/apiaiweb/projectAnalyzeMax/getSalesDataOverview',
  getSalesAnalysisLineChart: '/apiaiweb/projectAnalyzeMax/getSalesAnalysisLineChart',
  getYearOnYearSalesAnalysis: '/apiaiweb/projectAnalyzeMax/getYearOnYearSalesAnalysis',
  industryLoop: '/apiaiweb/projectAnalyzeMax/industryLoop',
  chainTypeRatio: '/apiaiweb/projectAnalyzeMax/chainTypeRatio',
  getDataRankingVo: '/apiaiweb/projectAnalyzeMax/getDataRankingVo',
  brandsData: '/apiaiweb/projectAnalyzeMax/brandsData',
  getSalesFigures: '/apiaiweb/projectAnalyzeMax/getSalesFigures',
  title: '/apiaiweb/projectAnalyzeMax/title',
  getRentAnalysis: '/apiaiweb/projectAnalyzeMax/getRentAnalysis',
  getRentValueVo: '/apiaiweb/projectAnalyzeMax/getRentValueVo',
  projectDistribution: '/apiaiweb/projectAnalyzeMax/projectDistribution',

  // v5.2版本接口
  approvalChainIsNotRnabled: '/apiaiweb/berthTask/approvalChainIsNotRnabled',
  fabuBerthTaskChain: '/apiaiweb/berthTask/fabuBerthTaskChain',
  ChainPicture: '/apiaiweb/berthTask/ChainPicture',
  queryFlowChartData: '/apiaiweb/berthTask/queryFlowChartData',
  getInformation: '/apiaiweb/berthTarget/GetInformation',
  getTargetResult: '/apiaiweb/berthTarget/getTargetResult',
  multipleTasksOneApprovalSheet: '/apiaiweb/berthTarget/multipleTasksOneApprovalSheet',
  approvalSheetStatus: '/apiaiweb/berthTarget/approvalSheetStatus',
  gdwsApprovalChainIsNotRnabled: '/apiaiweb/berthTarget/gdwsApprovalChainIsNotRnabled',
  queryTargetFlowChartData: '/apiaiweb/berthTarget/queryFlowChartData',

  updateUserChina: '/apiaiweb/berthTask/updateUserChina',
  deleteChinaMerchantsTask: '/apiaiweb/berthTask/deleteChinaMerchantsTask',
  getdeleteapprovalsContent: '/apiaiweb/berthTask/getdeleteapprovalsContent',
  isGdwsApprovalChainIsNotRnabled: '/apiaiweb/berthTask/gdwsApprovalChainIsNotRnabled',
  // 企业品牌库接口
  enterpriseBrandList: '/apiaiweb/brand/list',
  deleteBrand: '/apiaiweb/brand/deleteBrand',
  getBrandInfo: '/apiaiweb/brand/getBrandInfo',
  getBrandImage: '/apiaiweb/brand/getBrandImage',
  export: '/apiaiweb/brand/export',
  getLinkMan: '/apiaiweb/brand/getLinkMan',
  getSignedByGbid: '/apiaiweb/brand/getSignedByGbid',
  getBrandLog: '/apiaiweb/brand/getBrandLog',
  getLinkManRegions: '/apiaiweb/brand/getLinkManRegions',
  checkEnterpriseTabelBrandData: '/apiaiweb/berthPlanBrand/checkEnterpriseTabelBrandData',
  exportBrandToEnterpriseBrandTableSingular: '/apiaiweb/berthPlanBrand/exportBrandToEnterpriseBrandTable',

  // 店铺可视化分析
  // 招商规划视图
  getShopsIndoorMap: '/apiaiweb/shopsController/getShopsIndoorMap', // 获取招商规划地图
  merchantsPlanDateview: '/apiaiweb/shopsController/merchantsPlanDateview', // 数据概览
  floorArea: '/apiaiweb/shopsController/floorArea', // 楼层面积达成 尧滨滨】
  floorBerth: '/apiaiweb/shopsController/floorBerth', // 楼层铺位达成
  industryArea: '/apiaiweb/shopsController/industryArea', // 业态面积达成
  industryBerth: '/apiaiweb/shopsController/industryBerth', // 业态铺位达成
  merchantsProgressCount: '/apiaiweb/shopsController/merchantsProgressCount', // 招商进度统计
  // 合同租决视图
  getSignedIndoorMap: '/apiaiweb/signedController/getSignedIndoorMap', // 合同租决视图地图
  contractDataView: '/apiaiweb/signedController/contractDataView', // 合同租决数据概览新增【尧滨滨】
  floorRentReach: '/apiaiweb/signedController/floorRentReach', // 楼层租金达成
  industryRentReach: '/apiaiweb/signedController/industryRentReach', // 业态租金达成
  yearRentCompleteRate: '/apiaiweb/signedController/yearRentCompleteRate', // 年度租金完成进度
  pactanalysis: '/apiaiweb/signedController/pactanalysis', // 合同周期分析
  pactduration: '/apiaiweb/signedController/pactduration', // 合同剩余时长统计
  // 租金坪效视图
  getRentIndoorMap: '/apiaiweb/rentController/getRentIndoorMap', // 租金坪效视图地图
  floorRentContribution: '/apiaiweb/rentController/floorRentContribution', // 楼层租金贡献
  industryRentContribution: '/apiaiweb/rentController/industryRentContribution', // 业态租金贡献
  yearMonthlyRentAreaEffect: '/apiaiweb/rentController/yearMonthlyRentAreaEffect', // 年度租金坪效
  lastMonthStoreRentRank: '/apiaiweb/rentController/lastMonthStoreRentRank', // 上月店铺租金排行
  lastMonthStoreRentAreaAffectRank: '/apiaiweb/rentController/lastMonthStoreRentAreaAffectRank', // 上月店铺租金坪效排行
  dataOverviewRent: '/apiaiweb/rentController/dataOverview', // 数据概览
  // 销售坪效视图
  getRentIndoorMapSales: '/apiaiweb/sellController/getRentIndoorMap', // 销售坪效视图地图
  floorSellAreaAffect: '/apiaiweb/sellController/floorSellAreaAffect', // 楼层销售坪效
  industrySellAreaAffect: '/apiaiweb/sellController/industrySellAreaAffect', // 业态销售坪效
  yearSellAndPassengerFlowTendency: '/apiaiweb/sellController/yearSellAndPassengerFlowTendency', // 年度销售/客流趋势
  monthStoreSellRank: '/apiaiweb/sellController/monthStoreSellRank', // 本月店铺销售额排行
  monthStoreSellAreaAffect: '/apiaiweb/sellController/monthStoreSellAreaAffect', // 本月店铺销售坪效排行
  dataOverviewSales: '/apiaiweb/sellController/dataOverview', // 数据概览
  // 账单欠费视图
  getBillIndoorMap: '/apiaiweb/billController/getBillIndoorMap', // 账单欠费视图地图
  costCollectionAnalysis: '/apiaiweb/billController/costCollectionAnalysis', // 费用收缴分析
  arrearsAgingAnalysis: '/apiaiweb/billController/arrearsAgingAnalysis', // 欠费账龄分析
  storeArrearsAmountRank: '/apiaiweb/billController/storeArrearsAmountRank', // 店铺欠费金额排名
  dataOverviewOverdue: '/apiaiweb/billController/dataOverview', // 项目数据概览
  // 空置留存视图
  getRetentionIndoorMap: '/apiaiweb/retentionController/getRetentionIndoorMap', // 空置留存视图地图
  floorVacancyRetentionPeriod: '/apiaiweb/retentionController/floorVacancyRetentionPeriod', // 楼层空置留存周期
  industryVacancyRetentionPeriod: '/apiaiweb/retentionController/industryVacancyRetentionPeriod', // 业态空置留存周期
  berthVacancyPeriod: '/apiaiweb/retentionController/berthVacancyPeriod', // 铺位空置周期
  berthRetentionPeriod: '/apiaiweb/retentionController/berthRetentionPeriod', // 铺位留存周期
  berthVacancyPeriodRank: '/apiaiweb/retentionController/berthVacancyPeriodRank', // 铺位空置周期排行
  berthRetentionPeriodRank: '/apiaiweb/retentionController/berthRetentionPeriodRank', // 铺位留存周期排行
  dataOverviewVacancy: '/apiaiweb/retentionController/dataOverview', // 数据概览
  // 地图
  berthInfo: '/apiaiweb/visualStoreInfoController/berthInfo', // 铺位信息新增【许建华】
  contractInfo: '/apiaiweb/visualStoreInfoController/contractInfo', // 合同信息新增【许建华】
  lease: '/apiaiweb/visualStoreInfoController/lease', // 租决信息新增【尧滨滨】
  storeBillInfo: '/apiaiweb/visualStoreInfoController/storeBillInfo', // 店铺账单收缴信息新增【许建华】
  storeInfo: '/apiaiweb/visualStoreInfoController/storeInfo', // 店铺信息新增【许建华】
  storeRentAndRentAreaAffect: '/apiaiweb/visualStoreInfoController/storeRentAndRentAreaAffect', // 店铺租金/租金坪效新增【许建华】
  storeSellAndSellAreaAffect: '/apiaiweb/visualStoreInfoController/storeSellAndSellAreaAffect', // 商户(店铺)销售额/销售坪效新增【许建华】
  storeVacancyRetentionInfo: '/apiaiweb/visualStoreInfoController/storeVacancyRetentionInfo', // 店铺空置留存信息新增【许建华】
  // v5.3
  merchantsActualizeConfigureList: '/apiaiweb/management/merchantsActualizeConfigureList', // 管理中心-项目管理-招商实施配置查询
  merchantsActualizeConfigureUpdate: '/apiaiweb/management/merchantsActualizeConfigureUpdate', // 管理中心-项目管理-招商实施配置修改
  merchantsActualizeConfigureDelete: '/apiaiweb/management/merchantsActualizeConfigureDelete', // 管理中心-项目管理-招商实施配置删除
  merchantsActualizeConfigure: '/apiaiweb/management/merchantsActualizeConfigure', // 管理中心-项目管理-招商实施配置新增
  mainSecondaryStoreConfigureList: '/apiaiweb/management/mainSecondaryStoreConfigureList', // 管理中心-项目管理-主次力店配置查询
  mainSecondaryStoreConfigure: '/apiaiweb/management/mainSecondaryStoreConfigure', // 管理中心-项目管理-主次力店配置新增
  mainSecondaryStoreConfigureUpdate: '/apiaiweb/management/mainSecondaryStoreConfigureUpdate', // 管理中心-项目管理-主次力店配置修改
  mainSecondaryStoreConfigureDelete: '/apiaiweb/management/mainSecondaryStoreConfigureDelete', // 管理中心-项目管理-主次力店配置删除
  updateTaskPlanOrRealityTime: '/apiaiweb/berthTask/updateTaskPlanOrRealityTime', // 跟进详情-修改计划||完成时间
  getTaskOptionalFields: '/apiaiweb/berthTask/getTaskOptionalFields', // 发布招商任务选填/必填
  updateTaskPlanOrRealityTimeChain: '/apiaiweb/berthTask/updateTaskPlanOrRealityTimeChain', // 审批计划||时间时间
  getTaskApprovalStatus: '/apiaiweb/berthTask/getTaskApprovalStatus', // 审批计划||时间时间
  // v5.4.0
  // 基础数据
  brandLabel: '/apiaiweb/v5.4/warning/baseInfo/brandLabel', // 获取品牌标签post
  attentionStore: '/apiaiweb/v5.4/warning/baseInfo/attentionStore', // 关注/取消关注店铺
  getBerthTarget: '/apiaiweb/v5.4/warning/baseInfo/getBerthTarget', // 基础数据-当月合同/租决对比-租决
  getContractCostInfo: '/apiaiweb/v5.4/warning/baseInfo/getContractCostInfo', // 基础数据-当月合同/租决对比-合同
  getIndexNums: '/apiaiweb/v5.4/warning/baseInfo/getIndexNums', // 指数得分
  getStoreBaseData: '/apiaiweb/v5.4/warning/baseInfo/getStoreBaseData', // 基础数据
  getStoreSideInfo: '/apiaiweb/v5.4/warning/baseInfo/getStoreSideInfo', // 侧边栏店铺数据
  searchStore: '/apiaiweb/v5.4/warning/baseInfo/searchStore', // 查询店铺
  getStoreWarning: '/apiaiweb/v5.4/warning/baseInfo/getStoreWarning', // 获取店铺预警视图数据
  getBaseInfoDefaultCompareStore: '/apiaiweb/v5.4/warning/baseInfo/getDefaultCompareStore', // 获取对比店铺
  // 店铺预警分析-视图、监控设置
  addOrUpdateWarningRule: '/apiaiweb/v5.4/warning/index/addOrUpdateWarningRule', // 新增或修改mallCode下的预警规则(会清除清除统计指标的缓存)
  applyRanges: '/apiaiweb/v5.4/warning/index/applyRanges', // 获取应用范围列表
  clearCache: '/apiaiweb/v5.4/warning/index/clearCache', // 清除统计指标的缓存
  getConditionTypeList: '/apiaiweb/v5.4/warning/index/getConditionTypeList', // 查询当前预警规则的条件运算符
  getDpWarningRules: '/apiaiweb/v5.4/warning/index/getDpWarningRules', // 查询预警规则
  getMeasureList: '/apiaiweb/v5.4/warning/index/getMeasureList', // 查询当前所有的统计指标
  getStoreWarningViewData: '/apiaiweb/v5.4/warning/index/getStoreWarningViewData', // 获取店铺预警视图数据
  // 店铺预警分析-销售分析
  getStoreDateRangeSaleCompare: '/apiaiweb/v5.4/warning/salesAnalysis/getStoreDateRangeSaleCompare', // 时段销售对比
  getStoreSaleCompare: '/apiaiweb/v5.4/warning/salesAnalysis/getStoreSaleCompare', // 店铺销售对比
  getStoreSalesGoodsPageList: '/apiaiweb/v5.4/warning/salesAnalysis/getStoreSalesGoodsPageList', // 销售货品对比
  getStoreSalesRank: '/apiaiweb/v5.4/warning/salesAnalysis/getStoreSalesRank', // 销售排行分析
  getStoreSalesRelateAnalysis: '/apiaiweb/v5.4/warning/salesAnalysis/getStoreSalesRelateAnalysis', // 销售关联分析
  // 店铺预警分析-租金分析
  getStoreRentCompareByCondition: '/apiaiweb/v5.4/warning/rentAnalysis/getStoreRentCompareByCondition', // 店铺销售对比
  getStoreRentRank: '/apiaiweb/v5.4/warning/rentAnalysis/getStoreRentRank', // 租金排行分析
  getStoreRentRelateAnalysis: '/apiaiweb/v5.4/warning/rentAnalysis/getStoreRentRelateAnalysis', // 租金关联分析
  getStoreDateRentCompareByCondition: '/apiaiweb/v5.4/warning/rentAnalysis/getStoreDateRentCompareByCondition', // 店铺时段租金对比
  // 导出数据(高德的接口)
  exportDaySalesData: '/apiexport/dp/webApi/sjjc/salesAnalysis/exportDaySalesData', // 导出店铺日销售数据
  exportGoodsData: '/apiexport/dp/webApi/sjjc/salesAnalysis/exportGoodsData', // 导出店铺货品数据
  exportCompareGoodsData: '/apiexport/dp/webApi/sjjc/salesAnalysis/exportCompareGoodsData', // 导出对比店铺日销售数据
  exportRentData: '/apiexport/dp/webApi/sjjc/rentAnalysis/exportRentData', // 导出店铺日销售数据

  //  招调推荐
  getBuildingForFloor: '/apiaiweb/recruitCompart/storeinfo',
  getRecommendByBrand: '/apiaiweb/recruitCompart/recommendByBrand',
  getRecommendBerth: '/apiaiweb/recruitCompart/recommendByBerth',
  // 选址分析
  brandEntryPreference: '/apiaiweb/projectCompart/brandEntryPreference',
  // 评测分析
  getEvaluationAnalysisByStoreCode: '/apiaiweb/v5.4/warning/evaluationAnalysis/getEvaluationAnalysisByStoreCode',
  getTargetRentCompleteRatioCompare: '/apiaiweb/v5.4/warning/evaluationAnalysis/getTargetRentCompleteRatioCompare',
  getDefaultCompareStore: '/apiaiweb/v5.4/warning/evaluationAnalysis/getDefaultCompareStore',
  getEvaluationCompareByStoreCode: '/apiaiweb/v5.4/warning/evaluationAnalysis/getEvaluationCompareByStoreCode',
  // 项目比对
  projectRecommend: '/apiaiweb/projectCompart/recommend',
  getTabComprojectList: '/apiaiweb/projectCompart/getTabComprojectList',
  afterProjectInfo: '/apiaiweb/projectCompart/afterProjectInfo',
  addCompart: '/apiaiweb/projectCompart/addCompart',
  deleteCompart: '/apiaiweb/projectCompart/deleteCompart',
  getRecentProjectList: '/apiaiweb/projectCompart/getRecentProjectList',
  searchProject: '/apiaiweb/projectCompart/search',
  seating: '/apiaiweb/projectCompart/seating',
  projectInfoList: '/apiaiweb/projectCompart/projectInfoList',
  brandcompart: '/apiaiweb/projectCompart/brandcompart',
  sameFormatComparing: '/apiaiweb/projectCompart/sameFormatComparing',
  portrait: '/apiaiweb/projectCompart/portrait',
  addRecentProject: '/apiaiweb/projectCompart/addRecentProject',
  // 保存项目选择状态
  getProjectId: '/apiaiweb/listParamsLog/getProjectId',
  updateProjectId: '/apiaiweb/listParamsLog/updateProjectId',
  getListParamsLog: '/apiaiweb/listParamsLog/getListParamsLog',
  // 动态获取用户企业logo
  getCompanyLogo: '/apiaiweb/user/getCompanyLogo',
  getGdFloor: '/apiaiweb/berthIndoorMap/getGdFloor',

  // v5.5.0
  // 铺位招商管理
  batchSettingPrincipal: '/apiaiweb/berthTaskMng/batchSettingPrincipal',
  sublevelList: '/apiaiweb/berthTaskMng/sublevelList',
  searchBrandForCloud: '/apiaiweb/branddb/souceSearchLlist',
  exportReportCtr: '/apiaiweb/v5.5/planning/planFall/exportReportCtr',
  saveProgressControlConfigure: '/apiaiweb/berthTask/saveProgressControlConfigure',
  getProgressControlConfigure: '/apiaiweb/berthTask/getProgressControlConfigure',
  berthTaskProgressControl: '/apiaiweb/berthTask/berthTaskProgressControl',
  getProgressControlTargetLog: '/apiaiweb/berthTask/getProgressControlTargetLog',
  saveProgressControlTargetLog: '/apiaiweb/berthTask/saveProgressControlTargetLog',
  deleteProgressControlTargetLog: '/apiaiweb/berthTask/deleteProgressControlTargetLog',
  getUserListByProjectId: '/apiaiweb/user/getUserListByProjectId',
  berthRentInformation: '/apiaiweb/examineAndApproveChain/berthRentInformation',
  shopSigningInformation: '/apiaiweb/berthTaskMng/shopSigningInformation',
  intentionBrandLevel: '/apiaiweb/berthTaskMng/intentionBrandLevel',
  followingBrand: '/apiaiweb/berthTaskMng/followingBrand',
  updatePrincipal: '/apiaiweb/berthTaskMng/updatePrincipal',
  berthDeleteBrand: '/apiaiweb/berthTaskMng/DeleteBrand',
  DeleteFollowBrand: '/apiaiweb/berthTaskMng/DeleteFollowBrand',
  intentionBrandLevelDelete: '/apiaiweb/berthTaskMng/intentionBrandLevelDelete',
  fabuBerthTaskfive: 'apiaiweb/berthTask/fabuBerthTaskfive',
  addBrandFollowUp: 'apiaiweb/berthFollow/addBrandFollowUp',
  intentionToPlaceTheBunk: 'apiaiweb/berthTask/intentionToPlaceTheBunk',
  intentionOfThisUnit: 'apiaiweb/berthTask/intentionOfThisUnit',
  deleteIntentionToPlaceTheBunk: 'apiaiweb/berthTask/deleteIntentionToPlaceTheBunk',
  getRentPlan: 'apiaiweb/berthPlanBrand/getRentPlan',
  // 招商规划管理-租赁决策概览
  decisions: 'apiaiweb/v5.5/planning/decisions',
  submitDecisions: 'apiaiweb/v5.5/planning/decisions',
  addDecisions: 'apiaiweb/v5.5/planning/addDecisions',
  updateDecisions: 'apiaiweb/v5.5/planning/updateDecisions',
  getGdBuildingFloor: 'apiaiweb/berthIndoorMap/getGdBuildingFloor',
  costItem: 'apiaiweb/v5.5/planning/decisions/costItem',
  handleDecisions: 'apiaiweb/v5.5/planning/decisions/',
  berthPlanListAndLoop: 'apiaiweb//v5.5/planning/decisions/berthPlan/listAndLoop',
  berthPlanList: 'apiaiweb//v5.5/planning/decisions/berthPlan/list',
  getBerthPlanCard: 'apiaiweb/v5.5/planning/decisions/berthPlan/card',
  decisionsHistogram: 'apiaiweb/v5.5/planning/decisions/rentPlan/histogram', // 获取租金计划分布柱状图
  decisionsRentList: 'apiaiweb/v5.5/planning/decisions/rentPlan/list',
  decisionsStep: 'apiaiweb/v5.5/planning/decisions/step',
  decisionsOverview: 'apiaiweb/v5.5/planning/decisions/overview',
  handleStoreForm: 'apiaiweb/v5.5/planning/strategy/berth', // 获取主次力店策略表单 GET 设置主次力店策略 POST
  getStoreList: 'apiaiweb/v5.5/planning/strategy/berthList', // 获取主次力店策略列表 GET
  getStorePie: 'apiaiweb/v5.5/planning/strategy/berthLoop', // 获取主次力店策略环图 GET
  getStoreBar: 'apiaiweb/v5.5/planning/strategy/berthStrategyHistogram', // 获取主次力店策略柱状图 GET
  handleBrandDecisions: 'apiaiweb/v5.5/planning/strategy/brand', // 获取品牌策略 GET 设置品牌策略 POST
  getBrandDecisionsPie: 'apiaiweb/v5.5/planning/strategy/brandLoop', // 获取品牌策略环图 GET
  handleFormatDecisionsForm: 'apiaiweb/v5.5/planning/strategy/industry', // 获取业态策略表单 GET 设置业态策略 POST
  getFormatDecisionsBar: 'apiaiweb/v5.5/planning/strategy/industryHistogram', // 获取业态策略柱状图 GET
  getFormatDecisionsList: 'apiaiweb/v5.5/planning/strategy/industryList', // 获取业态策略列表 GET
  getFormatDecisionsPie: 'apiaiweb/v5.5/planning/strategy/industryLoop', // 获取业态策略环图 GET
  getFormatDecisionsOverview: 'apiaiweb/v5.5/planning/strategy/overview', // 获取策略概览 GET
  planOverview: 'apiaiweb/v5.5/planning/planFall/overview',
  exportRentPlan: 'apiaiweb/v5.5/planning/planFall/exportRentPlan',
  batchUpdateUser: 'apiaiweb/berthTask/batchUpdateUser',
  batchUpdatetime: 'apiaiweb/berthTask/batchUpdatetime',
  getBerthTaskOverview: 'apiaiweb/berthTask/getBerthTaskOverview',
  getBrandsAndFocus: 'apiaiweb/brand/getBrandsAndFocus',
  allPriceIncomeScale: 'apiaiweb/v5.5/planning/decisions/rentPlan/allPriceIncomeScale',
  getIntentionBerthByGbid: 'apiaiweb/berthPlanBrand/getIntentionBerthByGbid',
  checkIsThrough: 'apiaiweb/berthPlanBrand/checkIsThrough',
  decisionsExportNum: 'apiaiweb/v5.5/planning/decisions/export/',
  // 租金计划
  leasingPlanBatchSave: 'apiaiweb/v5.5/planning/rentplan/batchSave', // 批量插入数据新增【尧滨滨】
  importBerth: 'apiaiweb/v5.5/planning/rentplan/importBerth', // 导入铺位模板新增【尧滨滨】
  downloadBerth: 'apiaiweb/v5.5/planning/rentplan/downloadBerth', // 下载铺位模板新增【尧滨滨】
  editRentPlan: 'apiaiweb/v5.5/planning/rentplan/editRentPlan', // 编辑租金计划详情新增【尧滨滨】
  getInfoToParm: 'apiaiweb/v5.5/planning/rentplan/getInfoToParm', // 根据业态以及主次力店和计算面积类型获取租赁年限、策略单价、免租时长、计算面积新增【尧滨滨】
  leasingPlanList: 'apiaiweb/v5.5/planning/rentplan/list', // 获取租金计划管理列表新增【尧滨滨】
  getRentInfo: 'apiaiweb/v5.5/planning/planFall/getRentInfo', // 获取租金计划管理列表新增【尧滨滨】
  exportRentPlan2: 'apiaiweb/v5.5/planning/rentplan/exportRentPlan', // 导出

  // 租赁决策对比
  histogram: 'apiaiweb/v5.5/planning/compare/berthPlan/histogram', // 新兴/连锁品牌对比
  overview: 'apiaiweb/v5.5/planning/compare/overview', // 获取数据总览
  combination: 'apiaiweb/v5.5/planning/compare/decisions/berthPlan/combination', // 获取品牌组合
  floorIndustryPieAndLine: 'apiaiweb/v5.5/planning/compare/floorIndustryPieAndLine', // 获取楼层业态构成
  rentPlanHistogramAndBar: 'apiaiweb/v5.5/planning/compare/rentPlanHistogramAndBar', // 获取租金计划柱状图及条形图
  labelPortrait: 'apiaiweb/v5.5/planning/compare/berthPlan/labelPortrait', // 品牌标签画像
  industryCompare: 'apiaiweb/v5.5/planning/compare/industryCompare', // 业态策略对比

  // 租赁决策分析
  incomeAnalysis: 'apiaiweb/v5.5/planning/analysis/incomeAnalysis', // 租赁决策收入分析
  reachTrend: 'apiaiweb/v5.5/planning/analysis/reachTrend', // 租赁决策达成趋势
  reachInformation: 'apiaiweb/v5.5/planning/analysis/reachInformation', // 分指标达成情况
  getGdBuildingFloorList: 'apiaiweb/berthIndoorMap/getGdBuildingFloorList', // 获取楼宇楼层信息列表
  completeRateTrend: 'apiaiweb/v5.5/planning/analysis/completeRateTrend', // 招商完成率与租决完成率趋势
  reachInfoBerthPlan: 'apiaiweb/v5.5/planning/analysis/reachInfo/berthPlan', // 落位计划达成情况
  brandStrategy: 'apiaiweb/v5.5/planning/analysis/reachInfo/brandStrategy', // 品牌策略达成情况
  berthPlanCard: 'apiaiweb/v5.5/planning/compare/decisions/berthPlan/card', // 获取落位计划卡片
  brandCompare: 'apiaiweb/v5.5/planning/strategy/brandCompare', // 获取品牌策略对比
  getDecisionFloorList: 'apiaiweb/berthIndoorMap/getDecisionFloorList', // 获取租赁决策楼宇楼层信息列表

  // 招商规划管理-落位计划管理-竞品项目对比
  addCompartAtCompetition: 'apiaiweb/v5.5/planning/compete/addCompart', // 加入对比栏新增
  addRecentProjectAtCompetition: 'apiaiweb/v5.5/planning/compete/addRecentProject', // 添加对比项目（点击对比）
  afterProjectInfoList: 'apiaiweb/v5.5/planning/compete/afterProjectInfoList', // 点击对比之后的项目列表信息
  deleteCompartAtCompetition: 'apiaiweb/v5.5/planning/compete/deleteCompart', // 移出对比栏
  getBrandNumVoList: 'apiaiweb/v5.5/planning/compete/getBrandNumVoList', // 项目周边业态竞争对比
  getRecentProjectListAtCompetition: 'apiaiweb/v5.5/planning/compete/getRecentProjectList', // 最近对比项目列表
  getTabComprojectListAtCompetition: 'apiaiweb/v5.5/planning/compete/getTabComprojectList', // 获取已经添加在对比栏的项目列表
  lcorytCompart: 'apiaiweb/v5.5/planning/compete/lcorytCompart', // 同楼层/业态品牌对比
  competitionRecommend: 'apiaiweb/v5.5/planning/compete/recommend', // 获取推荐对比项目列表
  searchAtCompetition: 'apiaiweb/v5.5/planning/compete/search', // 项目搜索执行匹配

  // v5.5.0.20211009
  // 云端项目库
  getIsRealData: 'apiaiweb/project/getIsRealData', // 是否线下踩盘
  // 项目详情分析
  getBrandLabelPicture: 'apiaiweb/project/getBrandLabelPicture', // 品牌标签画像
  getIndustrySignedAreaPie: 'apiaiweb/project/getIndustrySignedAreaPie', // 业态面积占比饼图
  getBrandGradePie: 'apiaiweb/project/getBrandGradePie', // 品牌档次占比饼图
  getBrandScalePie: 'apiaiweb/project/getBrandScalePie', // 品牌级次占比饼图
  getEmergingBrandPie: 'apiaiweb/project/getEmergingBrandPie', // 新兴品牌占比饼图
  getChainBrandPie: 'apiaiweb/project/getChainBrandPie', // 连锁品牌占比饼图
  getBrandNationalityPie: 'apiaiweb/project/getBrandNationalityPie', // 品牌国籍占比饼图
  getprojectLouCengSpendingPower: 'apiaiweb/project/getprojectLouCengSpendingPower', // 楼层客群消费力
  getprojectSpendingPower: 'apiaiweb/project/getprojectSpendingPower', // 业态客群消费力
  getprojectOpenCloseAnalyse: 'apiaiweb/project/getprojectOpenCloseAnalyse', // 项目开关店分析
  getprojectYeTaiAnalyse: 'apiaiweb/project/getprojectYeTaiAnalyse', // 半年内各业态开关数量
  getprojectLouCengAnalyse: 'apiaiweb/project/getprojectLouCengAnalyse', // 半年内各楼层开关数量
  // 品牌清单
  getSignedBrandChart: 'apiaiweb/project/getSignedBrandChart', // 获取已签约品牌图表
  getSignedBrandList: 'apiaiweb/project/getSignedBrandList', // 获取已签约品牌列表

  // v5.7.0
  // 表单接口
  saveBasic: 'apiaiweb/flowConfig/saveBasic',
  updateBasic: 'apiaiweb/flowConfig/updateBasic',
  editWorkConfigHtml: 'apiaiweb/flowConfig/editWorkConfigHtml',
  getFlowConfigFieldRule: 'apiaiweb/flowConfig/getFlowConfigFieldRule',
  saveFlowNode: 'apiaiweb/flowNode/saveFlowNode',
  getWorkConfigHtml: 'apiaiweb/flowConfig/getWorkConfigHtml',
  getBasic: 'apiaiweb/flowConfig/basic',
  commit: 'apiaiweb/v5.7/workordercenter/commit',
  changeForm: 'apiaiweb/v5.7/workordercenter/change',
  getFlowConfigField: 'apiaiweb/flowNode/getFlowConfigField',
  getAllReceiptProvinceCityList: 'apiaiweb/flowConfig/getAllReceiptProvinceCityList',
  getWorkConfigCamunda: 'apiaiweb/flowConfig/getWorkConfigCamunda',
  getFlowNodeInfo: 'apiaiweb/flowConfig/getFlowNodeInfo',
  getFormContent: 'apiaiweb/v5.7/workordercenter/form',
  commitForm: 'apiaiweb/v5.7/workordercenter/commitForm',
  uploadFile: 'apiaiweb/flowConfig/uploadFile',
  uploadFileMerchant: 'apiaiweb/api/authTokenFilter/uploadFile', // 商户小程序-文件上传
  commitFormForMerchant: 'apiaiweb/api/authTokenFilter/commitForm', // 商户小程序-表单数据回显
  getMerchantForm: 'apiaiweb/api/authTokenFilter/form', // 商户小程序-工单内容
  commitMerchant: 'apiaiweb/api/authTokenFilter/commit', // 商户小程序-提交工单
  // 获取高级控件数据
  getAdvancedControlsOfProjectAndFoor: 'apiaiweb/flowConfig/getAdvancedControlsOfProjectAndFoor',
  getAdvancedControlsOfProject: 'apiaiweb/flowConfig/getAdvancedControlsOfProject',
  getAllMerchantList: 'apiaiweb/flowConfig/getAllMerchantList',
  getAllMerchantListForMerchant: 'apiaiweb/api/authTokenFilter/getAllMerchantList', // 商户小程序-获取商户
  getAllReceiptProvinceCityListForMerchant: 'apiaiweb/api/authTokenFilter/getAllReceiptProvinceCityList', // 商户小程序-获取省份城市区域
  getAdvancedControlsOfProjectForMerchant: 'apiaiweb/api/authTokenFilter/getAdvancedControlsOfProject', // 商户小程序-获取项目楼宇楼层商户店铺
  // 流程分组
  getFlowConfigListByGroup: 'apiaiweb/flowGroup/getFlowConfigListByGroup', // 按分组返回流程列表
  getFlowGroupList: 'apiaiweb/flowGroup/getFlowGroupList', // 查询工单流程分组列表
  saveFlowGroupList: 'apiaiweb/flowGroup/saveFlowGroupList', // 保存工单流程分组-批量操作增删改
  searchFlowConfigListByName: 'apiaiweb/flowGroup/searchFlowConfigListByName', // 根据流程名称搜索流程列表
  getFlowConfigListByGroupOfReceiptCenter: 'apiaiweb/flowGroup/getFlowConfigListByGroupOfReceiptCenter', // 工单中心-按分组返回流程列表
  getFlowGroupListOfReceiptCenter: 'apiaiweb/flowGroup/getFlowGroupListOfReceiptCenter', // 查询工单流程分组列表
  addCommonUse: 'apiaiweb/flowGroup/addCommonUse', // 增加常用
  deleteCommonUse: 'apiaiweb/flowGroup/deleteCommonUse', // 删除常用
  // 流程管理
  workConfigStartOrStop: 'apiaiweb/flowConfig/workConfigStartOrStop', // 流程启用/停用
  workConfigCopy: 'apiaiweb/flowConfig/workConfigCopy', // 复制流程
  // 工作组管理
  listWorkTeam: 'apiaiweb/receipt/listWorkTeam', // 查看工作组
  saveOrUpdateWorkTeam: 'apiaiweb/receipt/saveOrUpdateWorkTeam', // 新增修改工作组
  deleteFlowConfig: 'apiaiweb/flowConfig/deleteFlowConfig', // 删除工单流程
  getFlowConfigCascadeList: 'apiaiweb/flowConfig/getFlowConfigCascadeList', //
  // 工单中心
  workordercenterList: 'apiaiweb/v5.7/workordercenter/list', // 获取列表
  tablist: 'apiaiweb/v5.7/workordercenter/tablist', // 获取工单列表左侧tab栏
  getReceiptInfo: 'apiaiweb/v5.7/workordercenter/getReceiptInfo', // 根据工单编号获取工单详情
  associatedReceiptList: 'apiaiweb/v5.7/workordercenter/associatedReceiptList', // 根据工单编号获取关联单据
  receiptFlowLog: 'apiaiweb/v5.7/workordercenter/receiptFlowLog', // 根据工单编号获取流程记录
  receiptFlow: 'apiaiweb/v5.7/workordercenter/receiptFlow', // 根据工单编号获取流程记录
  acceptWork: 'apiaiweb/v5.7/workordercenter/accept', // 受理工单
  assigned: 'apiaiweb/v5.7/workordercenter/assigned', // 工单指派
  focus: 'apiaiweb/v5.7/workordercenter/focus', // 是否关注
  reminder: 'apiaiweb/v5.7/workordercenter/reminder', // 催单
  completeWork: 'apiaiweb/v5.7/workordercenter/complete', // 完成工单
  closeWork: 'apiaiweb/v5.7/workordercenter/close', // 关闭工单
  isloseWork: 'apiaiweb/v5.7/workordercenter/islose', // 校验是否正常关闭
  activateWork: 'apiaiweb/v5.7/workordercenter/activate', // 激活工单
  associated: 'apiaiweb/v5.7/workordercenter/associated', // 关联工单
  changework: 'apiaiweb/v5.7/workordercenter/change', // 变更工单
  getSelectNode: 'apiaiweb/v5.7/workordercenter/getSelectNode', // 获取业务节点
  getAssignHandler: 'apiaiweb/v5.7/workordercenter/getAssignHandler', // 获取指派处理人
  getSelectReceiptId: 'apiaiweb/v5.7/workordercenter/getSelectReceiptId', // 关联工单--模糊搜索工单
  submitEvaluation: 'apiaiweb/v5.7/workordercenter/submitEvaluation', // 提交评价
  submitComment: 'apiaiweb/v5.7/workordercenter/submitComment', // 提交评论
  transfer: 'apiaiweb/v5.7/workordercenter/transfer', // 转交工单
  exportWork: 'apiaiweb/v5.7/workordercenter/export', // 导出工单
  deleteAssociated: 'apiaiweb/v5.7/workordercenter/deleteAssociated', // 取消关联
  getEvaluationList: 'apiaiweb/v5.7/workordercenter/getEvaluationList', // 查询评价栏目
  // 工单-流程节点
  getHandlerList: 'apiaiweb/flowNode/getHandlerList', // 获取处理人 抄送人列表
  getSponsorList: 'apiaiweb/flowNode/getSponsorList', // 获取发起人列表
  getRegionList: 'apiaiweb/branddb/getRegionList',

  // 京华城检测是否有联系人
  countBrandLinkmanNums: 'apiaiweb/linkman/countBrandLinkmanNums'
}
