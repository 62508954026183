import * as types from './types'

// 设置token
export const setToken = ({ commit, state }, res) => {
  commit(types.SET_TOKEN, res.token)
  commit(types.SET_USER, res.user)
}

// 设置自定义品牌列表
export const setSelfBrandList = ({ commit, state }, list) => {
  commit(types.SET_SELF_LIST, list)
}

// 设置自定义招商搜索条件
export const setSelfSearch = ({ commit, state }, condition) => {
  commit(types.SET_SELF_SEARCH, condition)
}

// 设置请求失败
export const setLoadingError = ({ commit, state }, loading) => {
  commit(types.SET_LOADING_ERROR, loading)
}

// 设置招商选择品牌列表
export const setInvestmentBrandList = ({ commit, state }, list) => {
  commit(types.SET_INVESTMENT_LIST, list)
}

// 设置选中品牌gbids
export const setGbIdsList = ({ commit, state }, list) => {
  commit(types.SET_GBIDS_LIST, list)
}
