import * as types from '../types'
// initial state
const state = {
  selfList: [],
  selfSearch: {
    pageSize: 48,
    pageNum: 1,
    loading: true
  }
}

// mutations
const mutations = {
  // 设置自定义招商列表
  [types.SET_SELF_LIST] (state, selfList) {
    // state.selfList = Object.assign([], selfList)
    state.selfList = selfList
  },
  // 设置自定义招商搜索条件
  [types.SET_SELF_SEARCH] (state, selfSearch) {
    for (var key in selfSearch) {
      /* eslint-disable */
      if (selfSearch.hasOwnProperty(key)) {
        state.selfSearch[key] = selfSearch[key]
      }
      /* eslint-enable */
    }
  }
}

export default {
  state,
  mutations
}
